export const INIT_SUCCESS = 'INIT_SUCCESS';
export const UPDATE_PHOTO = 'UPDATE_PHOTO';
export const INIT_FAIL = 'INIT_FAIL';
export const LOGIN = 'LOGIN';
export const REGISTER_EMAIL = 'REGISTER_EMAIL';
export const CREATE_USERNAME = 'CREATE_USERNAME';
export const REGISTER_USER = 'REGISTER_USER';
export const LOGOUT = 'LOGOUT';
export const FETCH_USER_NEWSLETTERS = 'FETCH_USER_NEWSLETTERS';
export const FETCH_USER_FAVORITES = 'FETCH_USER_FAVORITES';
export const FETCH_USER_READ = 'FETCH_USER_READ';
export const UPDATE_USER_NEWSLETTERS = 'UPDATE_USER_NEWSLETTERS';
export const UPDATE_USER_NEWSLETTERS_REMOVE = 'UPDATE_USER_NEWSLETTERS_REMOVE';
export const LOAD_USER_FOLDERS = 'LOAD_USER_FOLDERS';
export const ADD_USER_FOLDER = 'ADD_USER_FOLDER';
export const UPDATE_USER_FAVORITES = 'UPDATE_USER_FAVORITES';
export const UPDATE_USER_FAVORITES_REMOVE = 'UPDATE_USER_FAVORITES_REMOVE';
export const UPDATE_USER_READ = 'UPDATE_USER_READ';
export const UPDATE_USER_READ_REMOVE = 'UPDATE_USER_READ_REMOVE';
export const ADD_NEWSLETTER_TO_FOLDER = 'ADD_NEWSLETTER_TO_FOLDER';
export const ADD_NEWSLETTER_TO_ANOTHER_FOLDER = 'ADD_NEWSLETTER_TO_ANOTHER_FOLDER';
export const RETURN_NEWSLETTER_TO_MAIN_FOLDER = 'RETURN_NEWSLETTER_TO_MAIN_FOLDER';
export const REMOVE_NEWSLETTER_FROM_FOLDER = 'REMOVE_NEWSLETTER_FROM_FOLDER';
export const EDIT_FOLDER_NAME = 'EDIT_FOLDER_NAME';
export const DELETE_USER_FOLDER = 'DELETE_USER_FOLDER';
export const LOAD_SINGLE_FOLDER = 'LOAD_SINGLE_FOLDER';
export const SUBSCRIBE_TO_MANY_NEWSLETTERS_FROM_PUBLIC_FOLDER =
    'SUBSCRIBE_TO_MANY_NEWSLETTERS_FROM_PUBLIC_FOLDER';
export const MAKE_FOLDER_PUBLIC = 'MAKE_FOLDER_PUBLIC';
export const MAKE_FOLDER_PRIVATE = 'MAKE_FOLDER_PRIVATE';
export const FETCH_USER_UNSEEN = 'FETCH_USER_UNSEEN';
export const REMOVE_FROM_UNSEEN = 'REMOVE_FROM_UNSEEN';
export const CHANGE_POSITIONS_NEWSLETTERS = 'CHANGE_POSITIONS_NEWSLETTERS';
export const CHANGE_POSITIONS_NEWSLETTERS_FOLDER = 'CHANGE_POSITIONS_NEWSLETTERS_FOLDER';
export const LOAD_USER_NEWSLETTERS_NOT_IN_FOLDERS = 'LOAD_USER_NEWSLETTERS_NOT_IN_FOLDERS';
export const MARK_ALL_ARTICLES_FROM_NEWSLETTER_AS_READ = 'MARK_ALL_ARTICLES_FROM_NEWSLETTER_AS_READ';
export const FETCH_USER_PLAN = 'FETCH_USER_PLAN';
