import { ReactComponent as LogoIcon } from './assets/logoLight.svg';
import { ReactComponent as BurgerIcon } from './assets/burgerMenuLight.svg';
import { ReactComponent as SearchIcon } from './assets/searchIcon.svg';
import { ReactComponent as CloseIcon } from './assets/closeIcon.svg';
import { openSideMenu, closeSideMenu } from '../../../actions/sideMenuActions';
import { fetchUsersFolders } from '../../../actions/folderActions';
import {
    getUserFolders,
    getUserId,
    getUserUnseen,
    getUserNewslettersNotInFolder,
} from '../../../reducers/userReducer';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './PhoneMenu.scss';
const PhoneMenu = ({
    openSideMenu,
    closeSideMenu,
    fetchUsersFolders,
    opened,
    folders,
    userId,
    userUnseen,
    userNewslettersNotInFolder,
}) => {
    const [searchVisible, setSearchVisible] = useState(false);
    const [visibleInput, setVisibleInput] = useState(false);
    const [inputFieldValue, setInputFieldValue] = useState('');
    const history = useHistory();

    useEffect(() => {}, [opened, userUnseen]);

    const handleSideMenu = () => {
        opened ? closeSideMenu() : openSideMenu();
        if (folders.length === 0) {
            fetchUsersFolders(userId);
        }
    };

    const handleSearch = () => {
        if (inputFieldValue) {
            setInputFieldValue('');
            history.push(`/search?query=${inputFieldValue}`);
        }
    };
    const handleSearchKey = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const handleInputChange = (e) => {
        setInputFieldValue(e.target.value);
    };

    const handleVisibilitySearch = (e) => {
        if (searchVisible) {
            setSearchVisible(false);
            setVisibleInput(false);
        } else {
            setSearchVisible(true);
            setTimeout(() => {
                setVisibleInput(true);
            }, 500);
        }
    };

    return (
        <div className='phone-wrapper'>
            <LogoIcon className='phone-menu-icons' />
            <div className='phone-menu-action'>
                {searchVisible ? (
                    <div className='header-search-wrapper'>
                        <div
                            className='header-search white-box-header'
                            onClick={handleVisibilitySearch}
                        >
                            <label htmlFor='search-input-field'>
                                <SearchIcon className='phone-menu-icons search-dark-icon loupe-icon' />
                            </label>
                        </div>
                        {visibleInput && (
                            <input
                                type='search'
                                autoComplete="off"
                                className='header-search-input'
                                id='search-input-field'
                                onKeyPress={handleSearchKey}
                                onChange={handleInputChange}
                                value={inputFieldValue}
                            ></input>
                        )}
                    </div>
                ) : (
                    <div className='header-search-wrapper-small'>
                        <div
                            className='header-search white-box-header'
                            onClick={handleVisibilitySearch}
                        >
                            <label htmlFor='search-input-field'>
                                <SearchIcon className='phone-menu-icons search-dark-icon loupe-icon ' />
                            </label>
                        </div>
                    </div>
                )}
                {opened ? (
                    <CloseIcon
                        className='phone-menu-icons close-icon-phone'
                        onClick={handleSideMenu}
                    />
                ) : (
                    <>
                        <BurgerIcon
                            className='phone-menu-icons burger-menu-icon'
                            onClick={handleSideMenu}
                        />
                        {userUnseen.length > 0 ? (
                            <div
                                className='phone-menu-notification'
                                onClick={handleSideMenu}
                            />
                        ) : null}
                    </>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    opened: state.sideMenu.opened,
    folders: getUserFolders(state),
    userId: getUserId(state),
    userUnseen: getUserUnseen(state),
    userNewslettersNotInFolder: getUserNewslettersNotInFolder(state),
});

const mapDispatchToProps = {
    openSideMenu,
    closeSideMenu,
    fetchUsersFolders,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneMenu);
