import ButtonSocial from './ButtonSocial';
import { ReactComponent as FacebookLogo } from './assets/facebook-button.svg';
import { ReactComponent as GoogleLogo } from './assets/google-button.svg';
import NotificationUser from '../../shared/NotificationUser';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { signInWithGoogle, facebookLogin } from '../../../actions/userActions';
import './ButtonsSocial.scss';
import { notifyUser } from '../../../helpers/notifications';
import { loginError } from '../../../helpers/firebaseErrorHandler';
import { useState } from 'react';

const ButtonsSocial = ({ signInWithGoogle, facebookLogin, actionText }) => {
    const history = useHistory();
    const [notification, setNotification] = useState({ type: '', message: '' });

    const handleGoogleRegister = async () => {
        try {
            const isNewUser = await signInWithGoogle();
            isNewUser
                ? history.push('/choose-username')
                : history.push('/latest');
        } catch (error) {
            if (error.error === loginError) {
                notifyUser(
                    'error',
                    'An account already exists with the same email address but different sign-in method.',
                    setNotification
                );
            }
            console.log(error);
        }
    };

    const facebookHandler = async () => {
        try {
            const isNewUser = await facebookLogin();
            isNewUser
                ? history.push('/choose-username')
                : history.push('/latest');
        } catch (error) {
            if (error.error === loginError) {
                notifyUser(
                    'error',
                    'An account already exists with the same email address but different sign-in method.',
                    setNotification
                );
            }
            console.log(error);
        }
    };

    return (
        <div className='social-section-wrappers'>
            <NotificationUser notification={notification} />
            <div className='social-buttons-wrapper'>
                <ButtonSocial
                    textContent={`${actionText} with Google`}
                    className='google-button'
                    handleClick={handleGoogleRegister}
                >
                    <GoogleLogo className='google-logo' />
                </ButtonSocial>
                <ButtonSocial
                    textContent={`${actionText} with Facebook`}
                    className='facebook-button'
                    handleClick={facebookHandler}
                >
                    <FacebookLogo className='facebook-logo' />
                </ButtonSocial>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    facebookLogin,
    signInWithGoogle,
};

export default connect(null, mapDispatchToProps)(ButtonsSocial);
