import {
    FETCH_ALL_CATEGORIES,
    CREATE_CATEGORY,
    DELETE_CATEGORY,
    EDIT_CATEGORY,
    CHANGE_POSITIONS,
    FETCH_NEWSLETTERS_BY_CATEGORY,
} from '../actionTypes/categoryTypes';
import { reorder } from '../helpers/arrayHelper';

const initialCategoryState = {
    _id: '',
    name: '',
    position: 0,
    newsletters: [],
};

const categories = (state = [], action) => {
    switch (action.type) {
        case FETCH_ALL_CATEGORIES:
            return action.payload.map((x) => ({
                ...initialCategoryState,
                ...x,
            }));
        case CREATE_CATEGORY:
            return [...state, { ...initialCategoryState, ...action.payload }];
        case DELETE_CATEGORY:
            return state.filter((x) => x._id !== action.payload);
        case EDIT_CATEGORY:
            return state.map((x) =>
                x._id === action.id ? { ...x, ...action.payload } : x
            );
        case CHANGE_POSITIONS:
            return reorder(
                state,
                action.payload.startIndex,
                action.payload.endIndex
            );
        case FETCH_NEWSLETTERS_BY_CATEGORY:
            return state.map((x) =>
                x._id === action.categoryId
                    ? { ...x, newsletters: action.payload }
                    : x
            );
        default:
            return state;
    }
};

export default categories;
export const getAllCategories = (state) => state.categories;
export const getNewslettersByCategory = (state, categoryId) =>
    state.categories.find((x) => x._id === categoryId).newsletters;
