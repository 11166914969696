import { userApi } from './api';
import requester from '../services/requester';

// addFolder: (userId) => `${USER_URL}/${userId}/folders`,
//     findFolders: (userId) => `${USER_URL}/${userId}/folders`,
//     addNewsletterToUsersFolder: (userId, folderId) =>
//         `${USER_URL}/${userId}/folders/${folderId}`,
//     findNewslettersForFolder: (userId, folderId) =>
//         `${USER_URL}/${userId}/folders/${folderId}`,

export default {
    addFolder: (userId, name) =>
        requester.post(userApi.addFolder(userId), { name }),
    findFolders: (userId) => requester.get(userApi.findFolders(userId)),
    addNewsletterToUsersFolder: (
        userId,
        newsletterId,
        folderId,
        sourceFolderId
    ) =>
        requester.post(userApi.addNewsletterToUsersFolder(userId, folderId), {
            newsletterId,
            sourceFolderId,
        }),
    returnNewsletterToMainFolder: (folderId, newsletterId, userId, position) =>
        requester.post(userApi.returnNewsletterToMainFolder(userId, folderId), {
            newsletterId,
            position,
        }),
    changePositionsInFolder: (
        userId,
        folderId,
        newsletterId,
        destinationIndex
    ) =>
        requester.post(userApi.changePositionsInFolder(userId, folderId), {
            newsletterId,
            destinationIndex,
        }),
    addNewsletterToAnotherFolder: (
        userId,
        folderId,
        newsletterId,
        destinationFolderId
    ) =>
        requester.post(userApi.addNewsletterToAnotherFolder(userId, folderId), {
            newsletterId,
            destinationFolderId,
        }),
    findNewslettersForFolder: (id) =>
        requester.get(userApi.findNewslettersForFolder(id)),
    editFolderName: (id, folderName, folderId) =>
        requester.put(userApi.editFolderName(id, folderId), { folderName }),
    deleteFolder: (userId, folderId) =>
        requester.delete(userApi.deleteFolder(userId, folderId)),
    fetchSingleFolder: (folderId) =>
        requester.get(userApi.fetchSingleFolder(folderId)),
    makeFolderPublic: (userId, folderId) =>
        requester.put(userApi.makeFolderPublic(userId, folderId)),
    makeFolderPrivate: (userId, folderId) =>
        requester.put(userApi.makeFolderPrivate(userId, folderId)),
};
