import InputField from '../../../shared/InputField';
import MainHeading from '../../../shared/MainHeading';
import Button from '../../../shared/Button';
import { getUserEmail } from '../../../../reducers/userReducer';
import { register, login } from '../../../../actions/userActions';
import { connect } from 'react-redux';
import { useState } from 'react';
import useForm from '../../../../hooks/useForm';
import { useHistory } from 'react-router-dom';
import {
    validatePassword,
    comparePasswords,
} from '../../../../helpers/validators';
import { trackWithPixel } from '../../../../utils/facebook-pixel';
import './FormSignUpPassword.scss';

const FormSignUpPassword = ({ register, login, email }) => {
    const [values, setValues] = useForm({ password: '', confirmPassword: '' });
    const [passwordError, setPasswordError] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setPasswordError('');

        try {
            setLoading(true);
            validatePassword(values.password.trim());
            comparePasswords(values.password.trim(), values.confirmPassword.trim());
            await register(email, values.password.trim());
            await login(email, values.password.trim());
            trackWithPixel('Complete Registration', { email })
            history.push('/choose-username');
        } catch (error) {
            setLoading(false);
            setValues({ password: '', confirmPassword: '' });
            setPasswordError(error.code || error.error);
        }
    };

    return (
        <div className='form-signup-wrapper'>
            <form className='form-signup' onSubmit={handleSubmit}>
                <div>
                    <MainHeading>Sign Up</MainHeading>
                    <h3>Create your account</h3>
                    <InputField
                        placeholder='Password'
                        type='password'
                        defaultPlaceholder='Enter your password...'
                        value={values.password}
                        handler={setValues}
                        name='password'
                        error={passwordError}
                        id='sign-up-password'
                    />
                    <InputField
                        placeholder='Confirm Password'
                        type='password'
                        defaultPlaceholder='Confirm password...'
                        value={values.confirmPassword}
                        handler={setValues}
                        name='confirmPassword'
                        id='sign-up-confirm'
                    />
                    {loading ? (
                        <Button disabled>LOADING...</Button>
                    ) : (
                            <Button>CREATE ACCOUNT</Button>
                        )}
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state) => ({
    email: getUserEmail(state),
});

const mapDispatchToProps = {
    register,
    login,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormSignUpPassword);
