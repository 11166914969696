import { connect } from 'react-redux';
import { useEffect } from 'react';
import { fetchUsers, changeStatusUser, changeUserPlan } from '../../../../actions/usersActions';
import { getUsers } from '../../../../reducers/usersReducer';
import moment from 'moment';
import './UserList.scss';

const UserList = ({ users, fetchUsers, changeStatusUser, changeUserPlan }) => {
    useEffect(() => {
        fetchUsers();
    }, [fetchUsers, changeStatusUser]);

    const handleUserBlock = (id) => {
        changeStatusUser(id, true)
    };

    const handleUserUnBlock = (id) => {
        changeStatusUser(id, false)
    };

    const handleForcePremium = (id) => {
        changeUserPlan(id)
    }

    return (
        <div className='users-list-wrapper'>
            <table className='users-list-table'>
                <thead>
                    <tr>
                        <th className='user-list-th-email'>Email</th>
                        <th className='user-list-th-username'>Username</th>
                        <th className='user-list-th-status'>Status</th>
                        <th className='user-list-th-date'>Registration Date</th>
                        <th className='user-list-th-action'>Action</th>
                        <th className='user-list-th-action'>Plan</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((u) => (
                        <tr key={u._id}>
                            <td className='user-list-td-email'>{u.email}</td>
                            <td className='user-list-td-username'>
                                {u.username.split('@myletterbox.io')[0]}
                            </td>
                            <td className='user-list-td-status'>
                                {u.isBlocked ? (
                                    <span className='user-list-blocked'>
                                        Blocked
                                    </span>
                                ) : (
                                        <span className='user-list-active'>
                                            Active
                                        </span>
                                    )}
                            </td>
                            <td className='user-list-td-date'>
                                {u.dateRegistration
                                    ? moment(u.dateRegistration).format('MM/DD/YYYY')
                                    : 'No date'}
                            </td>
                            <td className='user-list-td-action'>
                                {u.isBlocked ? (
                                    <button
                                        className='button-action user-button-blocked'
                                        onClick={() => handleUserUnBlock(u._id)}
                                    >
                                        Unblock
                                    </button>
                                ) : (
                                        <button
                                            className='button-action user-button-active'
                                            onClick={() => handleUserBlock(u._id)}
                                        >
                                            Block
                                        </button>
                                    )}
                            </td>
                            <td className='user-list-td-premium'>
                                <p>{u.plan.name}</p>
                                {u.plan.name === 'BASE'
                                    && <button className='button-action user-button-active'
                                        onClick={e => handleForcePremium(u._id)}>Make <br /> Premium</button>
                                }

                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const mapStateToProps = (state) => ({
    users: getUsers(state),
});

const mapDispatchToProps = {
    fetchUsers,
    changeStatusUser,
    changeUserPlan
};
export default connect(mapStateToProps, mapDispatchToProps)(UserList);
