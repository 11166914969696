import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Draggable } from 'react-beautiful-dnd';

import { textCutter } from '../../../../../helpers/textCutter';
import { showAlternativeLogo } from '../../../../../helpers/showAlternativeLogo';
import { countUserUnreadArticles } from '../../../../../helpers/userProvider';
import {
    getUsername,
    getUserNewsletters,
    getUserNewslettersArticles,
    getUserNewslettersNotInFolder,
    getUserRead,
    getUserUnseen,
} from '../../../../../reducers/userReducer';
import { closeSideMenu } from '../../../../../actions/sideMenuActions';
import MoveToFolderModal from './ModalMoveToFolder';
import NewsletterOptions from './NewsletterOptions';
import { ReactComponent as OptionsFolderIcon } from '../assets/OptionsFolder.svg';
import { ReactComponent as OrangePointUnread } from '../../assets/orange-point-unread-icon.svg';
import { ReactComponent as NewsletterHandle } from '../../assets/Newsletter-handle.svg';
import { getArticle } from '../../../../../reducers/articleReducer';
import './NewsLettersInFolderNewsletters.scss';

const NewsLettersInFolderNewsletters = ({
    newsletter,
    read,
    index,
    closeSideMenu,
    setNotification,
    setHoverable,
    hoverable,
    userUnseen,
    currentNewsletter,
    setCurrentNewsletter,
    userNewsletters,
    userNewslettersNotInFolder,
    isPublic,
    article,
    userUsername,
}) => {
    const [newsletterOptions, setNewsletterOptions] = useState(false);
    const [positionYOptions, setPositionYOptions] = useState(0);
    const [modal, setModal] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [newsletterToUse, setNewsletterToUsed] = useState(newsletter);
    const history = useHistory();

    useEffect(() => {
        const location = history.location.pathname
            .split('/')
            .filter((w) => w !== '');

        if (location.includes('newsletters')) {
            const id = location.pop();

            if (id === newsletter._id) {
                setCurrentNewsletter(newsletter._id);
            }
        } else if (article.newsletter._id === newsletter._id) {
            setCurrentNewsletter(newsletter._id);
        }

        //Take updated articles
        if (userNewsletters.length) {
            setNewsletterToUsed(
                userNewsletters.find((n) => n._id === newsletter._id)
            );
        }
    }, [
        history.location.pathname,
        newsletter._id,
        setCurrentNewsletter,
        userNewslettersNotInFolder,
        article,
    ]);

    const onOptionsNewsletterHandleClick = (e) => {
        const cursorY = e.target.getBoundingClientRect().top;
        
        if (newsletterOptions) {
            setNewsletterOptions(false);
            setPositionYOptions(0);
        } else {
            e.currentTarget.focus();
            setNewsletterOptions(true);
            setPositionYOptions(cursorY+25);
        }
    };

    const onOptionsNewsletterBlurHandler = (e) => {
        const time = setInterval(function () {
            setNewsletterOptions(false);
            clearInterval(time);
        }, 200);
    };

    const handleCloseMenu = () => {
        const vw = Math.max(
            document.documentElement.clientWidth || 0,
            window.innerWidth || 0
        );
        setCurrentNewsletter(newsletter._id);
        if (vw <= 839) {
            closeSideMenu();
        }
    };

    const handleChangeImage = () => {
        setHovered((prevState) => !prevState);
    };

    const handleHover = (e) => {
        // const element = document.getElementsByClassName('newsletters-in-folder')[0];
        // const toScroll = index * 38;
        // element.scrollTop = 1938;
        // console.log(toScroll);
    };

    return (
        <Draggable
            key={newsletter._id}
            draggableId={newsletter._id}
            index={index}
            isDragDisabled={modal}
        >
            {(provided, snapshot) => (
                <div
                    className={`newsletter-in-folder-wrapper-newsletter${
                        hoverable ? ' hoverable' : ''
                    } ${
                        currentNewsletter === newsletter._id
                            ? 'current-newsletter-entered'
                            : ''
                    } ${snapshot.isDragging ? ' dragging' : ''}`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onMouseEnter={handleHover}
                >
                    <Link
                        to={'/newsletters/' + newsletter._id}
                        onClick={handleCloseMenu}
                    >
                        <div className={`newsletter-info-wrapper`}>
                            <img
                                src={newsletter.logo}
                                alt='logo'
                                onError={showAlternativeLogo}
                                className='handle-icon-wrapper'
                            />

                            <img
                                src={newsletter.logo}
                                alt='logo'
                                onError={showAlternativeLogo}
                                className='mini-logo'
                            />

                            <span className='change-opacity-newsletter'>
                                {textCutter(newsletter.name, 20)}
                            </span>
                        </div>
                    </Link>
                    <div className='newsletter-additional-info-wrapper'>
                        <div
                            tabIndex={index}
                            className={`options-folder-icon-wrapper${
                                newsletterOptions ? ' visible' : ''
                            }`}
                            onClick={onOptionsNewsletterHandleClick}
                            onBlur={onOptionsNewsletterBlurHandler}
                        >
                            <OptionsFolderIcon className='options-folder-icon' />
                        </div>

                        {countUserUnreadArticles(
                            newsletterToUse.articles,
                            read
                        ) > 0 && (
                            <>
                                <span className='change-opacity-newsletter'>
                                    {countUserUnreadArticles(
                                        newsletterToUse.articles,
                                        read
                                    ) >= 100
                                        ? '99+'
                                        : countUserUnreadArticles(
                                              newsletterToUse.articles,
                                              read
                                          )}
                                </span>
                                {userUnseen.find((u) =>
                                    newsletterToUse.articles.includes(u)
                                ) && (
                                    <OrangePointUnread className='orange-point-icon' />
                                )}
                            </>
                        )}
                        {newsletterOptions && (
                            <NewsletterOptions
                                positionY={positionYOptions}
                                newsletterId={newsletter._id}
                                setModal={setModal}
                                setNotification={setNotification}
                                setHoverable={setHoverable}
                                userUsername={userUsername}
                                isPublic={isPublic}
                            />
                        )}
                        {modal && (
                            <MoveToFolderModal
                                setModal={setModal}
                                newsletter={newsletter}
                                setNotification={setNotification}
                                setHoverable={setHoverable}
                            />
                        )}
                    </div>
                </div>
            )}
        </Draggable>
    );
};

const mapStateToProps = (state) => ({
    read: getUserRead(state),
    userUnseen: getUserUnseen(state),
    userNewsletters: getUserNewsletters(state),
    userNewslettersNotInFolder: getUserNewslettersNotInFolder(state),
    article: getArticle(state),
    userUsername: getUsername(state),
});

const mapDispatchToProps = {
    closeSideMenu,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsLettersInFolderNewsletters);
