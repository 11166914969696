import { ReactComponent as ScrollLogo } from '../assets/Scrollbar Indicator.svg';
import MiniNewsletter from './MiniNewsletter/MiniNewsletter';
import React from 'react';
import './OpenMiniNewsletters.scss';

const OpenMiniNewsletters = ({ newsletters }) => {
    return (
        <>
            <div className='small-newsletters'>
                {newsletters.map((x, i) => (
                    <MiniNewsletter newsletter={x} key={i} index={i} />
                ))}
            </div>
            {newsletters.length > 4 && (
                <ScrollLogo className='svg-icon scroll-logo-mini-newsletters' />
            )}
        </>
    );
};

export default OpenMiniNewsletters;
