import { Link } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from './assets/button-arrow.svg';
import './ArticleNext.scss';

const ArticleNext = ({
    title,
    newsletterName,
    newsletterLogo,
    newsletterId,
    time,
    url,
    content,
}) => {
    const handleClick = () => {
        window.scroll(0, 0);
    };

    const contentHTML = `${content}`;

    return (
        <div className='article-next-wrapper'>
            <p className='next-article-indicator'>Next Article</p>
            <div className='next-article-title-wrapper'>
                <div className='next-article-title'>
                    <Link
                        to={url}
                        onClick={handleClick}
                        className='next-article-title-link'
                    >
                        {title}
                    </Link>
                </div>
            </div>
            <div className='next-article-info-wrapper'>
                <div className='next-article-info'>
                    <Link to={`/newsletters/${newsletterId}`}>
                        <img
                            className='next-article-read-bullet'
                            src={newsletterLogo}
                            alt={newsletterName}
                        ></img>
                    </Link>
                    <div className='next-article-publisher-time'>
                        <Link to={`/newsletters/${newsletterId}`}>
                            {newsletterName}
                        </Link>
                        <p>{time}</p>
                    </div>
                </div>
                <Link to={url} className='next-article-button'>
                    <span>Read</span>
                    <ArrowIcon />
                </Link>
            </div>
            <div className='next-article-content'>
                <iframe
                    width='100%'
                    height='100%'
                    scrolling='no'
                    frameBorder='no'
                    srcDoc={contentHTML}
                ></iframe>
            </div>
        </div>
    );
};

export default ArticleNext;
