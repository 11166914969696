import CardArticles from './CardArticles/CardArticles';
import { Link, useHistory } from 'react-router-dom';
import { textCutter } from '../../../helpers/textCutter';
import { showAlternativeLogo } from '../../../helpers/showAlternativeLogo';
import { getUserNewsletters, getUserId } from '../../../reducers/userReducer';
import { connect } from 'react-redux';
import './ExploreCard.scss';

const ExploreCard = ({
    newsletter,
    description,
    articles,
    id,
    userEmail,
    userNewsletters,
    userToken,
    setModal,
    userId,
    isPublic,
    disabledLink,
}) => {
    let isSubscribed = userNewsletters.find((x) => x._id === id);
    const history = useHistory();

    const handleOpenNewsletter = (e) => {
        if (!userId) {
            setModal(true);
            return;
        } else if (
            e.target.className !== 'latest-article-explore-card' &&
            e.target.tagName !== 'SPAN' &&
            !disabledLink
        ) {
            history.push(`/newsletters/${id}`);
        }
    };
    return (
        <div
            className='explore-card-wrapper'
            onClick={
                isPublic !== undefined
                    ? isPublic
                        ? handleOpenNewsletter
                        : null
                    : handleOpenNewsletter
            }
        >
            <div className={`explore-card${isSubscribed ? ' subscribed' : ''}`}>
                <div className='logo-card'>
                    <img
                        src={newsletter.logo}
                        alt='l'
                        className='explore-card-mini-logo'
                        onError={showAlternativeLogo}
                    />
                </div>
                <div className='card-content'>
                    <div className='explore-card-newsletter'>
                        <h3>{newsletter.name}</h3>
                    </div>

                    <div className='explore-card-brief-description'>
                        <p>{textCutter(description, 110)}</p>
                    </div>

                    <CardArticles
                        userEmail={userEmail}
                        articles={articles}
                        newsletter={newsletter}
                        id={id}
                        userNewsletters={userNewsletters}
                        userToken={userToken}
                        setModal={setModal}
                        handleOpenNewsletter={handleOpenNewsletter}
                        isPublic={isPublic !== undefined ? isPublic : true}
                        disabledLink={disabledLink}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userNewsletters: getUserNewsletters(state),
    userId: getUserId(state),
});

export default connect(mapStateToProps)(ExploreCard);
