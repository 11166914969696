import { sendAmplitudeData } from '../../../../utils/amplitude';
import { useState, useEffect, useRef } from 'react';
import './ArticleContent.scss';

const ArticleContent = ({ newsletterName, content, id, userRole }) => {
    const [amplitude, setAmplitude] = useState(true);
    const iframeRef = useRef(null);
    const divRef = useRef(null);
    const [height, setHeight] = useState('1000px');
    const [visible, setVisible] = useState(true);
    const [disabledLink, setDisabledLinks] = useState(false);
    const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
    );

    useEffect(() => {
        if (amplitude) {
            sendAmplitudeData('Read Article', {
                publisher_name: newsletterName,
                article_id: id,
            });
            setAmplitude(false);
        }

        if (divRef.current) {
            setHeight(Number(divRef.current.offsetHeight) + 600 + 'px');
            setTimeout(() => {
                setVisible(false);
            }, 0);
        }
    }, [divRef]);

    if (iframeRef.current && !disabledLink) {
        const document = iframeRef.current.contentWindow.document;
        const arrayLinks = Array.from(document.getElementsByTagName('a'));

        if (arrayLinks.length) {
            setDisabledLinks(true);
        }

        const wordsToAvoid = [
            'unsubscribe',
            'manage',
            'email',
            'preferences',
            'settings',
            'change',
        ];

        arrayLinks.forEach((anchor) => {
            anchor.setAttribute('target', '_blank');

            if (userRole !== 'ADMIN') {
                const anchorTextArray = anchor.textContent
                    .split(' ')
                    .filter((w) => w.trim() !== '')
                    .map((w) => w.toLocaleLowerCase());

                const changeRef = anchorTextArray.some(function (w) {
                    return wordsToAvoid.indexOf(w) >= 0;
                });

                if (changeRef) {
                    anchor.href = '#';
                    anchor.target = '_blank';
                } else {
                    const documentHeight =
                        iframeRef.current.contentWindow.document.body
                            .scrollHeight;
                    const divOffset = offset(anchor);
                    const percentage = (divOffset.top / documentHeight) * 100;

                    if (percentage > 90 + documentHeight / 1000) {
                        anchor.href = '#';
                        anchor.target = '_blank';
                    }
                }
            }
        });
    }

    function offset(el) {
        const rect = el.getBoundingClientRect(),
            scrollTop =
                window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop };
    }

    const contentHTML = `${content}`;
    return (
        <>
            <div
                className={visible ? 'div-html-visible' : 'div-html-hidden'}
                dangerouslySetInnerHTML={{
                    __html: visible ? contentHTML : <p></p>,
                }}
                ref={divRef}
            ></div>

            {vw < 839 ? (
                <iframe
                    ref={iframeRef}
                    width='100%'
                    height={height}
                    scrolling='yes'
                    frameBorder='no'
                    srcDoc={contentHTML}
                    onLoad={() => {
                        setHeight(
                            Number(
                                iframeRef.current.contentWindow.document.body
                                    .scrollHeight
                            ) +
                                20 +
                                'px'
                        );
                    }}
                ></iframe>
            ) : (
                <iframe
                    ref={iframeRef}
                    width='100%'
                    height={height}
                    scrolling='yes'
                    frameBorder='no'
                    srcDoc={contentHTML}
                    onLoad={() => {
                        setHeight(
                            Number(
                                iframeRef.current.contentWindow.document.body
                                    .scrollHeight
                            ) +
                                20 +
                                'px'
                        );
                    }}
                ></iframe>
            )}
        </>
    );
};

export default ArticleContent;
