import './ProfileInput.scss';

const ProfileInput = ({
    name,
    children,
    type,
    inputId,
    value,
    isDisabled,
    handleChange,
    placeholder,
    textDisabled
}) => {
    return (
        <div className='profile-input-wrapper'>
            <input
                className={isDisabled ? "disabled-input" : ""}
                autoComplete='off'
                placeholder={placeholder || ''}
                name={name}
                value={value}
                id={inputId}
                type={type}
                disabled={isDisabled}
                onChange={handleChange}
            />
            <span className="disabled-text-input">{textDisabled}</span>
        </div>
    );
};

export default ProfileInput;
