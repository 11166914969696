import ReactPixel from 'react-facebook-pixel';

const options = {
    autoConfig: true,
    debug: false,
};
const id = '881914626046514';

export const initPixel = () => {
    ReactPixel.init(id, options);
    ReactPixel.pageView();

}

export const trackWithPixel = (event, data) => {
    ReactPixel.init(id, options);
    ReactPixel.track(event, data);
}