import LayoutMain from '../../layouts/LayoutMain';
import MainHeading from '../../shared/MainHeading';
import ListingArticlesSearch from '../../shared/ListingArticlesSearch';
import { useLocation } from 'react-router-dom';
import './SearchPage.scss';
import { useEffect } from 'react';
import SearchFilters from './SearchFilters/SearchFilters';

const SearchPage = () => {
    const searchingQuery = useLocation().search.substring(7).replace('%20', ' ');
    const indexFirstFilter = searchingQuery.indexOf('&');
    const queryToHeading = indexFirstFilter !== -1
        ? searchingQuery.slice(0, indexFirstFilter)
        : searchingQuery;

    useEffect(() => {

    }, [searchingQuery])

    return (
        // <LayoutMain>
        <div className='search-result-section'>
            <div className='search-result-title-section-wrapper'>
                <div className='search-result-title-section'>
                    <MainHeading
                        wrapperClassName='main-heading-onboard-wrapper'
                        className='normal-heading'
                    >
                        Search Results for
                        </MainHeading>
                    <div className="heading-and-filters-wrapper">
                        <p className='search-result-subtitle'>
                            {queryToHeading}
                        </p>
                        <SearchFilters query={searchingQuery} />
                    </div>
                </div>
            </div>
            <div className='search-result-main-section'>
                <ListingArticlesSearch query={searchingQuery} />
            </div>
        </div>
        // </LayoutMain>
    );
};

export default SearchPage;
