import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { getUserNewsletters } from '../../../reducers/userReducer';
import { sendAmplitudeData } from '../../../utils/amplitude';
import { useEffect } from 'react';
import MainHeading from '../../shared/MainHeading';
import Categories from '../../shared/Categories';
import ChosenNewslettersMenu from './ChosenNewslettersMenu';
import LatestCards from './LatestCards';
import PopularCards from './PopularCards';
import SortedLatestCards from './SortedLatestCards';
import SortedPopularCards from './SortedPopularCards';
import { ReactComponent as ArrowIcon } from './assets/button-arrow.svg';
import './OnboardingNewsletters.scss';

const OnboardingNewsletters = ({ match, userNewsletters }) => {
    const { params } = match;
    
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const handleAnalyticsReport = () => {
        //analytics
        sendAmplitudeData('Finish Onboarding', {
            publishers_added: userNewsletters.length,
        });
    };

    return (
        <div className='onboarding-newsletters-wrapper'>
            <div className='onboarding-newsletters'>
                <div className='heading-section'>
                    <div className='heading-section-headers'>
                        <MainHeading
                            wrapperClassName='main-heading-onboard-wrapper'
                            className='normal-heading'
                        >
                            Subscribe to your first newsletter before you continue
                        </MainHeading>
                        <ChosenNewslettersMenu
                            userNewsletters={userNewsletters}
                        />
                    </div>
                    {userNewsletters.length >= 1 ? (
                        <Link
                            to={{
                                pathname: `/newsletters/${userNewsletters[0]._id}`,
                                state: { toOpenSideMenuNewsletters: true },
                            }}
                            onClick={handleAnalyticsReport}
                            className='button-continue-filled'
                        >
                            <span>Continue</span>
                            <ArrowIcon />
                        </Link>
                    ) : (
                        <button className='button-continue'>
                            <span>Continue</span>
                            <ArrowIcon />
                        </button>
                    )}
                </div>

                <div className='onboarding-newsletters-main-section'>
                    <Categories />
                    {params.category ? (
                        <div>
                            {/* <SortedPopularCards category={params.category} /> */}
                            <SortedLatestCards
                                category={params.category}
                                disabledLink={true}
                            />
                        </div>
                    ) : (
                        <div>
                            {/* <PopularCards /> */}
                            <LatestCards disabledLink={true} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userNewsletters: getUserNewsletters(state),
});

export default connect(mapStateToProps)(OnboardingNewsletters);
