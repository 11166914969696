import { ReactComponent as CopyClipBoard } from '../../Pages/Profile/ProfileForm/assets/copy-icon.svg'
import { getUsername } from '../../../reducers/userReducer';
import { useHistory, userHistory } from 'react-router-dom';
import './EmptyStateOnboardingStyle.scss';
import { connect } from 'react-redux';

const EmptyStateOnboardingStyle = ({ username }) => {
    const history = useHistory();

    const copyToClipBoard = () => {
        navigator.clipboard.writeText(username);
    };

    const redirectToExplore = () => {
        history.push('/explore');
    }

    console.log('username: ', username);

    return <>
        <div className="empty-state-wrapper">
            <div className="empty-state-texts">
                <h1 className="empty-state-texts-heading">Start adding your favorite newsletters</h1>
                <p className="empty-state-texts-text">
                    Your feed is currently empty. Use your personal
                    Letterbox email address and subscribe to your
                    favorite newsletters. Alternatively, explore
                    our catalogue, we curated some neat stuff
                    for you there.
                </p>
            </div>
            <div className="empty-state-boxes">

                <div className='empty-state-boxes-box'>
                    <div className="empty-state-boxes-box-texts">
                        <h3>Your Letterbox Email</h3>
                        <p>
                            Use this address to subscribe for newsletters,
                            they'll show up on your left.
                        </p>
                    </div>
                    <div className='user-private-email'>
                        {username && (
                            <>
                                <div>
                                    <p>Letterbox email</p>
                                    <p className='user-private-email-value'>
                                        {username}
                                    </p>
                                </div>
                                <CopyClipBoard
                                    onClick={copyToClipBoard}
                                ></CopyClipBoard>
                            </>
                        )}
                    </div>
                </div>

                <div className='empty-state-boxes-box redirect' onClick={redirectToExplore}>
                    <div className="empty-state-boxes-box-texts">
                        <h3>Discover</h3>
                        <p>
                            We have found something for everybody. Try
                            finding interesting reads in our discovery section
                        </p>
                    </div>
                    <div className="empty-state-boxes-box-texts">
                        <p className="empty-state-boxes-box-texts-full-opacity">Explore Newsletters</p>
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => ({
    username: getUsername(state),
});


export default connect(mapStateToProps, null)(EmptyStateOnboardingStyle);