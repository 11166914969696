import {
    LOGIN,
    REGISTER_EMAIL,
    CREATE_USERNAME,
    REGISTER_USER,
    INIT_SUCCESS,
    INIT_FAIL,
    LOGOUT,
    FETCH_USER_NEWSLETTERS,
    FETCH_USER_FAVORITES,
    UPDATE_USER_NEWSLETTERS,
    UPDATE_USER_NEWSLETTERS_REMOVE,
    UPDATE_USER_FAVORITES,
    UPDATE_USER_FAVORITES_REMOVE,
    UPDATE_USER_READ,
    UPDATE_USER_READ_REMOVE,
    UPDATE_PHOTO,
    FETCH_USER_READ,
    SUBSCRIBE_TO_MANY_NEWSLETTERS_FROM_PUBLIC_FOLDER,
    FETCH_USER_UNSEEN,
    REMOVE_FROM_UNSEEN,
    MARK_ALL_ARTICLES_FROM_NEWSLETTER_AS_READ,
    FETCH_USER_PLAN
} from '../actionTypes/userTypes';
import { auth, googleProvider, facebookProvider } from '../utils/firebase';
import { setToLS, removeFromLS } from '../utils/storage';
import {
    sendAmplitudeData,
    setAmplitudeUserId,
    sendAmplitudeIdentifyData,
} from '../utils/amplitude';
import userService from '../services/userService';

const logoutSuccess = () => ({
    type: LOGOUT,
});

const setInitSuccess = (userInfo) => ({
    type: INIT_SUCCESS,
    payload: userInfo,
});

const setInitFail = () => ({
    type: INIT_FAIL,
});

const unsubscribeFromNewsletterSuccess = (id, folder) => ({
    type: UPDATE_USER_NEWSLETTERS_REMOVE,
    payload: { id, folder },
});

const fetchUserPlanSuccess = (planName) => ({
    type: FETCH_USER_PLAN,
    payload: planName,
});

const removeFromUnseenSuccess = (newsletterId) => ({
    type: REMOVE_FROM_UNSEEN,
    payload: newsletterId,
});

const fetchUserUnseenSuccess = (data) => ({
    type: FETCH_USER_UNSEEN,
    payload: data,
});

const markAllArticlesFromNewsletterAsReadAndSeenSuccess = (data) => ({
    type: MARK_ALL_ARTICLES_FROM_NEWSLETTER_AS_READ,
    payload: data,
});

export const loginSuccess = (userInfo) => ({
    type: LOGIN,
    payload: userInfo,
});

export const registerEmail = (email) => ({
    type: REGISTER_EMAIL,
    payload: email,
});

export const registerUser = (response) => ({
    type: REGISTER_USER,
    payload: response,
});

export const createUsername = (username) => ({
    type: CREATE_USERNAME,
    payload: username,
});

export const updatePhotoSuccess = (photoURL) => ({
    type: UPDATE_PHOTO,
    payload: photoURL,
});

export const subscribeForNewsletterSuccess = (newsletter) => ({
    type: UPDATE_USER_NEWSLETTERS,
    payload: newsletter,
});

export const addArticleToFavoritesSuccess = (article) => ({
    type: UPDATE_USER_FAVORITES,
    payload: article,
});

export const removeArticleFromFavoritesSuccess = (id) => ({
    type: UPDATE_USER_FAVORITES_REMOVE,
    payload: id,
});

export const removeArticleFromReadSuccess = (id) => ({
    type: UPDATE_USER_READ_REMOVE,
    payload: id,
});

export const addArticleToReadSuccess = (id) => ({
    type: UPDATE_USER_READ,
    payload: id,
});

export const fetchUserNewslettersSuccess = (data) => ({
    type: FETCH_USER_NEWSLETTERS,
    payload: data,
});

export const fetchUserFavoritesSuccess = (data) => ({
    type: FETCH_USER_FAVORITES,
    payload: data,
});

export const fetchUserReadSuccess = (data) => ({
    type: FETCH_USER_READ,
    payload: data,
});

export const subscribeToAllNewslettersFromFolderSuccess = (data) => ({
    type: SUBSCRIBE_TO_MANY_NEWSLETTERS_FROM_PUBLIC_FOLDER,
    payload: data,
});

export const login = (email, password) => async (dispatch) => {
    try {
        const { user } = await auth.signInWithEmailAndPassword(email, password);
        const token = await user.getIdToken();

        const amplitudeFunc = async () => {
            const response = await userService.findUser(email);
            const data = await response.json();

            setAmplitudeUserId(data._id);
            sendAmplitudeIdentifyData('email', email);
            sendAmplitudeData('User Sign In', {
                account_type: 'email',
            });
        };
        amplitudeFunc();

        dispatch(loginSuccess({ email, token }));
    } catch (error) {
        throw { code: error.code, message: error.message };
    }
};

export const register = (email, password) => async (dispatch) => {
    try {
        const response = await userService.register(email, password);
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        setAmplitudeUserId(data.id);
        sendAmplitudeIdentifyData('email', data.email);
        sendAmplitudeData('User Sign Up', {
            account_type: 'email',
        });

        dispatch(registerUser(data));
    } catch (error) {
        throw { error: error.message || error.error };
    }
};

export const signInWithGoogle = () => async (dispatch) => {
    try {
        const result = await auth.signInWithPopup(googleProvider);
        const { isNewUser } = result.additionalUserInfo;
        const { email } = result.user;
        let data;

        if (isNewUser) {
            let response = await userService.registerWithSocial(email);
            data = await response.json();

            const responseInitial = await userService.findUserInitialInfo(
                data._id
            );
            const dataInitial = await responseInitial.json();

            const responseUnseen = await userService.findUnseen(data._id);
            const dataUnseen = await responseUnseen.json();

            dispatch(fetchUserUnseenSuccess(dataUnseen?.unseen));

            dispatch(fetchUserNewslettersSuccess(dataInitial.newsletters));
            dispatch(fetchUserFavoritesSuccess(dataInitial.favorites));
            dispatch(fetchUserReadSuccess(dataInitial.read));

            if (data.error) {
                throw { error: data.error };
            }

            setAmplitudeUserId(data._id);
            sendAmplitudeIdentifyData('email', data.email);
            sendAmplitudeData('User Sign Up', {
                account_type: 'google',
            });
        } else {
            let response = await userService.findUser(email);
            data = await response.json();

            setAmplitudeUserId(data._id);
            sendAmplitudeIdentifyData('email', email);
            sendAmplitudeData('User Sign In', {
                account_type: 'google',
            });
        }

        dispatch(loginSuccess({ email, id: data ? data._id : '' }));
        return isNewUser;
    } catch (error) {
        throw { error: error.message || error.error };
    }
};

export const facebookLogin = () => async (dispatch) => {
    try {
        const result = await auth.signInWithPopup(facebookProvider);
        const { isNewUser } = result.additionalUserInfo;
        const { email } = result.user;
        let data = {};

        if (isNewUser) {
            let response = await userService.registerWithSocial(email);
            data = await response.json();

            const responseInitial = await userService.findUserInitialInfo(
                data._id
            );
            const dataInitial = await responseInitial.json();

            const responseUnseen = await userService.findUnseen(data._id);
            const dataUnseen = await responseUnseen.json();

            dispatch(fetchUserUnseenSuccess(dataUnseen?.unseen));

            dispatch(fetchUserNewslettersSuccess(dataInitial.newsletters));
            dispatch(fetchUserFavoritesSuccess(dataInitial.favorites));
            dispatch(fetchUserReadSuccess(dataInitial.read));

            if (data.error) {
                throw { error: data.error };
            }

            setAmplitudeUserId(data._id);
            sendAmplitudeIdentifyData('email', data.email);
            sendAmplitudeData('User Sign Up', {
                account_type: 'facebook',
            });
        } else {
            let response = await userService.findUser(email);
            data = await response.json();

            setAmplitudeUserId(data._id);
            sendAmplitudeIdentifyData('email', email);
            sendAmplitudeData('User Sign In', {
                account_type: 'facebook',
            });
        }

        dispatch(loginSuccess({ email, id: data ? data._id : '' }));
        return isNewUser;
    } catch (error) {
        throw { error: error.message || error.error };
    }
};

export const createUsernameOnExistingUser =
    (userId, username) => async (dispatch) => {
        try {
            const response = await userService.createUsernameOnExistingUser(
                userId,
                username
            );
            const data = await response.json();

            if (data.error) {
                throw { error: data.error };
            }
            sendAmplitudeData('Username created', {
                user_id: userId,
            });
            dispatch(createUsername(data.username));

        } catch (error) {
            throw { error: error.message || error.error };
        }
    };

export const fetchUserLocationChange = (userId) => async (dispatch) => {
    try {
        const responseInitial = await userService.findUserInitialInfo(userId);
        const dataInitial = await responseInitial.json();

        if (dataInitial.error) {
            throw { error: dataInitial.error };
        }

        dispatch(fetchUserNewslettersSuccess(dataInitial.newsletters));
        dispatch(fetchUserFavoritesSuccess(dataInitial.favorites));
        dispatch(fetchUserReadSuccess(dataInitial.read));

        const userPlan = await userService.findUserPlan(userId);
        const dataPlan = await userPlan.json();

        if (dataPlan.error) {
            throw { error: dataPlan.error };
        }

        dispatch(fetchUserPlanSuccess(dataPlan));

    } catch (error) {
        throw { error: error.message || error.error };
    }
};

export const markAllArticlesFromNewsletterAsReadAndSeen =
    (userId, newsletterId) => async (dispatch) => {
        try {
            const responseInitial =
                await userService.markAllArticlesFromNewsletterAsReadAndSeen(
                    userId,
                    newsletterId
                );
            const dataInitial = await responseInitial.json();

            if (dataInitial.error) {
                throw { error: dataInitial.error };
            }

            dispatch(
                markAllArticlesFromNewsletterAsReadAndSeenSuccess(dataInitial)
            );
        } catch (error) {
            throw { error: error.message || error.error };
        }
    };

export const verifyAuth = () => async (dispatch) => {
    auth.onAuthStateChanged(async (user) => {
        if (user) {
            removeFromLS('token');
            setToLS('role', 'USER');
            const photoURL = user.photoURL;
            const token = await user.getIdToken(true);
            const username = user.displayName;
            const email = user.email;
            const tokenResult = await auth.currentUser.getIdTokenResult();
            let id = tokenResult.claims.id;
            const role = tokenResult.claims.admin ? 'ADMIN' : 'USER';
            const plan = tokenResult.claims.plan;

            if (!id) {
                let { _id } = await (await userService.findUser(email)).json();
                id = _id;
            }
            setToLS('role', role);
            setToLS('token', token);

            dispatch(
                setInitSuccess({
                    email,
                    plan,
                    token,
                    role,
                    username: username || '',
                    photoURL: photoURL || '',
                    id,
                })
            );

            if (id) {
                const response = await userService.findUserInitialInfo(id);
                const data = await response.json();

                const responseUnseen = await userService.findUnseen(id);
                const dataUnseen = await responseUnseen.json();

                dispatch(fetchUserUnseenSuccess(dataUnseen?.unseen));

                dispatch(fetchUserNewslettersSuccess(data.newsletters || []));
                dispatch(fetchUserFavoritesSuccess(data.favorites));
                dispatch(fetchUserReadSuccess(data.read));
            } else {
                setTimeout(async () => {
                    const response = await userService.findUserInitialInfo(id);
                    const data = await response.json();

                    const responseUnseen = await userService.findUnseen(id);
                    const dataUnseen = await responseUnseen.json();

                    dispatch(fetchUserUnseenSuccess(dataUnseen?.unseen));

                    dispatch(
                        fetchUserNewslettersSuccess(data.newsletters || [])
                    );
                    dispatch(fetchUserFavoritesSuccess(data.favorites));
                    dispatch(fetchUserReadSuccess(data.read));
                }, 2000);
            }
            return;
        }

        dispatch(setInitFail());
        removeFromLS('role');
        removeFromLS('token');
    });
};

export const logout = () => async (dispatch) => {
    try {
        await auth.signOut();

        removeFromLS('role');
        removeFromLS('token');
        dispatch(logoutSuccess());
    } catch (error) {
        throw { error: error.message || error.error };
    }
};

export const stripeFetchCheckoutSession = (userId) => async (dispatch) => {
    try {
        const res = await userService.stripeFetchCheckoutSession(userId);

        const session = await res.json();

        return session;
    } catch (error) {
        throw { error: error.message || error.error };
    }
};
export const stripeManagePortalUrl = (userId) => async (dispatch) => {
    try {
        const res = await userService.stripeManagePortalUrl(userId);

        const session = await res.json();

        console.log(session);

        return session;
    } catch (error) {
        throw { error: error.message || error.error };
    }
};

export const stripeActionNeeded = (userId) => async (dispatch) => {
    try {
        const res = await userService.stripeActionNeeded(userId);

        const session = await res.json();

        return session;
    } catch (error) {
        throw { error: error.message || error.error };
    }
};

export const subscribeEmail = async (email) => {
    try {
        const res = await userService.preSubscribe(email.email);
        if (res.status === 400) {
            throw { error: 'Email already subscribed' };
        }
    } catch (error) {
        throw { error: error.message || error.error };
    }
};

export const subscribeForNewsletter = (userId, id) => async (dispatch) => {
    try {
        const res = await userService.subscribeForNewsletter(userId, id);
        const data = await res.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(
            subscribeForNewsletterSuccess({
                ...data,
            })
        );
    } catch (error) {
        throw { error: error.message || error.error };
    }
};

export const unsubscribeFromNewsletter =
    (userId, newsletterId, folder) => async (dispatch) => {
        try {
            const response = await userService.unsubscribeFromNewsletter(
                userId,
                newsletterId
            );
            const data = await response.json();

            if (data.error) {
                throw { error: data.error };
            }

            dispatch(unsubscribeFromNewsletterSuccess(newsletterId, folder));
        } catch (error) {
            throw { error: error.message || error.error };
        }
    };

export const addArticleToFavorites =
    (userId, articleId) => async (dispatch) => {
        try {
            const res = await userService.addArticleToFavorites(
                userId,
                articleId
            );
            const data = await res.json();

            if (data.error) {
                throw { error: data.error };
            }

            sendAmplitudeData('Add Favorite', {
                article_id: articleId,
            });

            dispatch(addArticleToFavoritesSuccess(articleId));
        } catch (error) {
            throw { error: error.message || error.error };
        }
    };

export const addArticleToRead = (userId, articleId) => async (dispatch) => {
    try {
        const res = await userService.addArticleToRead(userId, articleId);
        const data = await res.json();

        if (data.read) {
            return;
        }

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(addArticleToReadSuccess(articleId));
    } catch (error) {
        throw { error: error.message || error.error };
    }
};

export const removeArticleFromFavorites = (userId, id) => async (dispatch) => {
    try {
        const res = await userService.removeArticleFromFavorites(userId, id);

        const data = await res.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(removeArticleFromFavoritesSuccess(id));
    } catch (error) {
        throw { error: error.message || error.error };
    }
};

export const removeArticleFromRead = (userId, id) => async (dispatch) => {
    try {
        const res = await userService.removeArticleFromRead(userId, id);

        const data = await res.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(removeArticleFromReadSuccess(id));
    } catch (error) {
        throw { error: error.message || error.error };
    }
};

export const updatePassword =
    (userId, currentPassword, newPassword) => async (dispatch) => {
        try {
            const res = await userService.updatePassword(
                userId,
                currentPassword,
                newPassword
            );
            const data = await res.json();

            if (data.error) {
                throw { error: data.error };
            }

            return data;
        } catch (error) {
            throw { error: error.message || error.error };
        }
    };

export const updateName = (userId, name) => async (dispatch) => {
    try {
        const res = await userService.updateName(userId, name);
        const data = await res.json();

        if (data.error) {
            throw { error: data.error };
        }

        return data;
    } catch (error) {
        throw { error: error.message || error.error };
    }
};

export const updatePhoto = (userId, photoURL) => async (dispatch) => {
    try {
        const res = await userService.updatePhoto(userId, photoURL);

        const data = await res.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(updatePhotoSuccess(photoURL));
    } catch (error) {
        throw { error: error.message || error.error };
    }
};

export const fetchNameOfUser = (userId) => async (dispatch) => {
    try {
        const res = await userService.findNameOfUser(userId);

        const data = await res.json();

        if (data.error) {
            throw { error: data.error };
        }

        return data.name;
    } catch (error) {
        throw { error: error.message || error.error };
    }
};

export const checkSmtpStatus = async () => {
    try {
        const res = await userService.checkSmtpStatus();
        const data = await res.json();

        if (data.error) {
            throw { error: data.error };
        }
        return data.alive;
    } catch (error) {
        throw { error: error.message || error.error };
    }
};

export const subscribeToAllNewslettersFromFolder =
    (userId, newslettersIds) => async (dispatch) => {
        try {

            const response =
                await userService.subscribeToAllNewslettersFromFolder(
                    userId,
                    newslettersIds
                );

            const data = await response.json();

            if (data.error) {
                throw { error: data.error };
            }

            dispatch(subscribeToAllNewslettersFromFolderSuccess(data));
        } catch (err) {
            throw { error: err.error };
        }
    };

export const fetchUserUnseen = (userId) => async (dispatch) => {
    try {
        const response = await userService.findUnseen(userId);
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(fetchUserUnseenSuccess(data?.unseen));
        dispatch(fetchUserNewslettersSuccess(data?.newsletters));
    } catch (err) {
        throw { error: err.error };
    }
};

export const removeFromUnseen =
    ({ userId, newsletterId, articleId }) =>
        async (dispatch) => {
            try {
                const response = await userService.removeFromUnseen(
                    userId,
                    newsletterId,
                    articleId
                );
                const data = await response.json();

                if (data.error) {
                    throw { error: data.error };
                }

                dispatch(removeFromUnseenSuccess(data?.unseen));
            } catch (err) {
                throw { error: err.error };
            }
        };
