import { ReactComponent as NewslettersIcon } from '../assets/newsletters-icon.svg';
import { ReactComponent as OpenedNewsletters } from '../assets/NewsletterIconOpened.svg';
import { ReactComponent as ProfileIcon } from '../assets/ProfileIcon.svg';
import { ReactComponent as OrangePointUnread } from '../assets/orange-point-unread-icon.svg';
import { connect } from 'react-redux';
import {
    getUserNewsletters,
    getUserUnseen,
} from '../../../../reducers/userReducer';
import { openInputCreateFolder } from '../../../../actions/sideMenuActions';
import {
    openMiniNewsletters,
    closeMiniNewsletters,
} from '../../../../actions/sideMenuActions';
import OpenMiniNewsletters from '../OpenMiniNewsletters';
import QuickLinks from '../QuickLinks';
import './QuickAccessIcons.scss';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const QuickAccessIcons = ({
    newsletters,
    openMenu,
    openInputCreateFolder,
    menuSettingsClick,
    openMiniNewsletters,
    closeMiniNewsletters,
    miniNewsletters,
    userUnseen,
}) => {
    const [location, setLocation] = useState();
    const history = useHistory();
    const handleOpenMiniNewsletters = () => {
        miniNewsletters ? closeMiniNewsletters() : openMiniNewsletters();
    };
    const handleCreateFolderMini = () => {
        openMenu();
        openInputCreateFolder();
    };

    useEffect(() => {
        history.location.state?.toOpenSideMenuNewsletters &&
            openMiniNewsletters();
        setLocation(history.location.pathname);
    }, [openMiniNewsletters, history.location.pathname]);

    return (
        <div className='icon-side-menu-wrapper'>
            <div className='quick-links-and-newsletter-wrapper'>
                <QuickLinks location={location} />
                <div className='quick-newsletter-wrapper'>
                    <div
                        className={
                            miniNewsletters
                                ? 'open-side-menu-icon'
                                : 'side-menu-icon-wrapper'
                        }
                        onClick={handleOpenMiniNewsletters}
                    >
                        <div
                            className={`newsletter-icon-wrapper${
                                userUnseen.length > 0 ? ' unseen' : ''
                            }`}
                        >
                            <NewslettersIcon className='svg-icon newsletter-icon' />
                            {userUnseen.length > 0 && <OrangePointUnread className="OrangePointUnread"/>}
                        </div>
                    </div>
                    {miniNewsletters && (
                        <OpenMiniNewsletters newsletters={newsletters} />
                    )}
                </div>
            </div>
            <div className='side-menu-icon-wrapper' onClick={menuSettingsClick}>
                <ProfileIcon className='profile-icon svg-icon' />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    newsletters: getUserNewsletters(state),
    userUnseen: getUserUnseen(state),
    miniNewsletters: state.sideMenu.miniNewsletters,
});
const mapDispatchToProps = {
    openInputCreateFolder,
    openMiniNewsletters,
    closeMiniNewsletters,
};
export default connect(mapStateToProps, mapDispatchToProps)(QuickAccessIcons);
