import './CardButton.scss';
const CardButton = ({ buttonClasses, handleSubscribe, subscribed }) => {
    return (
        <>
            <button
                className={buttonClasses ? buttonClasses.join(' ') : ''}
                onClick={handleSubscribe}
            >
                {subscribed ? <span>Unsubscribe</span> : <span>Subscribe</span>}
            </button>
        </>
    );
};

export default CardButton;
