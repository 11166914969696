import ContentLoader from 'react-content-loader';
import './ArticlesLoader.scss';

const ArticlesLoader = () => {
    return (
        <div className='articles-loader-wrapper'>
            <div className='articles-loader'>
                <ContentLoader
                    speed={2}
                    width='100%'
                    height={150}
                    backgroundColor='#e1e1e1'
                    foregroundColor='#7c7c7c'
                    foregroundOpacity={0.7}
                >
                    <rect
                        x='4'
                        y='4'
                        rx='4'
                        ry='4'
                        width='120'
                        height='8'
                        className='newsletter-title'
                    />
                    <rect
                        x='355'
                        y='4'
                        rx='0'
                        ry='0'
                        width='120'
                        height='8'
                        className='article-title'
                    />
                    <rect
                        x='355'
                        y='40'
                        rx='0'
                        ry='0'
                        width='600'
                        height='7'
                        className='article-first-line'
                    />
                    <rect
                        x='355'
                        y='50'
                        rx='0'
                        ry='0'
                        width='300'
                        height='7'
                        className='article-second-line'
                    />
                    <circle cx='370' cy='109' r='12' className='left-circle' />
                    <circle cx='400' cy='109' r='12' className='right-circle' />
                    <rect
                        x='-100%'
                        y='112'
                        rx='0'
                        ry='0'
                        width='80'
                        height='6'
                        className='article-date'
                    />
                </ContentLoader>
            </div>
            <div className='articles-loader'>
                <ContentLoader
                    speed={2}
                    width='100%'
                    height={150}
                    backgroundColor='#e1e1e1'
                    foregroundColor='#7c7c7c'
                    foregroundOpacity={0.7}
                >
                    <rect
                        x='4'
                        y='4'
                        rx='4'
                        ry='4'
                        width='120'
                        height='8'
                        className='newsletter-title'
                    />
                    <rect
                        x='355'
                        y='4'
                        rx='0'
                        ry='0'
                        width='120'
                        height='8'
                        className='article-title'
                    />
                    <rect
                        x='355'
                        y='40'
                        rx='0'
                        ry='0'
                        width='600'
                        height='7'
                        className='article-first-line'
                    />
                    <rect
                        x='355'
                        y='50'
                        rx='0'
                        ry='0'
                        width='300'
                        height='7'
                        className='article-second-line'
                    />
                    <circle cx='370' cy='109' r='12' className='left-circle' />
                    <circle cx='400' cy='109' r='12' className='right-circle' />
                    <rect
                        x='-100%'
                        y='112'
                        rx='0'
                        ry='0'
                        width='80'
                        height='6'
                        className='article-date'
                    />
                </ContentLoader>
            </div>
            <div className='articles-loader'>
                <ContentLoader
                    speed={2}
                    width='100%'
                    height={150}
                    backgroundColor='#e1e1e1'
                    foregroundColor='#7c7c7c'
                    foregroundOpacity={0.7}
                >
                    <rect
                        x='4'
                        y='4'
                        rx='4'
                        ry='4'
                        width='120'
                        height='8'
                        className='newsletter-title'
                    />
                    <rect
                        x='355'
                        y='4'
                        rx='0'
                        ry='0'
                        width='120'
                        height='8'
                        className='article-title'
                    />
                    <rect
                        x='355'
                        y='40'
                        rx='0'
                        ry='0'
                        width='600'
                        height='7'
                        className='article-first-line'
                    />
                    <rect
                        x='355'
                        y='50'
                        rx='0'
                        ry='0'
                        width='300'
                        height='7'
                        className='article-second-line'
                    />
                    <circle cx='370' cy='109' r='12' className='left-circle' />
                    <circle cx='400' cy='109' r='12' className='right-circle' />
                    <rect
                        x='-100%'
                        y='112'
                        rx='0'
                        ry='0'
                        width='80'
                        height='6'
                        className='article-date'
                    />
                </ContentLoader>
            </div>
        </div>
    );
};

export default ArticlesLoader;
