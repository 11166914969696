import './Notification.scss';

const Notification = ({ notification }) => {
    return notification.message ? (
        <div className={`notification-wrapper ${notification.type}`}>
            <span>{notification.message}</span>
        </div>
    ) : null;
};

export default Notification;
