import LatestArticle from '../LatestArticle';
import { Link, useLocation } from 'react-router-dom';
import { textCutter } from '../../../../helpers/textCutter';
import { useEffect, useState } from 'react';
import CardButton from '../CardButton/CardButton';
import { connect } from 'react-redux';
import {
    subscribeForNewsletter,
    unsubscribeFromNewsletter,
} from '../../../../actions/userActions';
import { lastEntranceSuccess } from '../../../../actions/articleActions';
import { removeNewsletterFromFolderSuccess } from '../../../../actions/folderActions';
import {
    getUserId,
    getUserNewsletters,
} from '../../../../reducers/userReducer';
import { sendAmplitudeData } from '../../../../utils/amplitude';
import { trackWithPixel } from '../../../../utils/facebook-pixel';
import { ReactComponent as ArrowRight } from '../assets/arrow-right-icon.svg';
import './CardArticles.scss';

const CardArticles = ({
    articles,
    newsletter,
    userEmail,
    id,
    subscribeForNewsletter,
    unsubscribeFromNewsletter,
    userNewsletters,
    userId,
    lastEntranceSuccess,
    setModal,
    removeNewsletterFromFolderSuccess,
    isPublic,
    disabledLink,
}) => {
    const [buttonClasses, setButtonClasses] = useState([
        'explore-card-subscribed-button',
    ]);
    const [subscribed, setSubscribed] = useState(false);
    const article = articles[0];
    const location = useLocation();

    useEffect(() => {
        const check = userNewsletters.find((x) => x._id === id);

        if (check) {
            setSubscribed(true);
            setButtonClasses([
                ...buttonClasses,
                'explore-card-subscribed-button-isSubscribed',
            ]);
        } else {
            setSubscribed(false);
            setButtonClasses(['explore-card-subscribed-button']);
        }
    }, [
        userId,
        userEmail,
        userNewsletters,
        subscribeForNewsletter,
        unsubscribeFromNewsletter,
    ]);

    const handleChangeLastEntrance = () => {
        lastEntranceSuccess(location.pathname);
    };

    const handleSubscribe = async (e) => {
        e.preventDefault();
        if (!userId) {
            setModal(true);
            return;
        }
        try {
            if (subscribed) {
                await unsubscribeFromNewsletter(userId, id);
                await removeNewsletterFromFolderSuccess(id);

                setSubscribed(false);
                setButtonClasses(['explore-card-subscribed-button']);

                //analytics
                sendAmplitudeData('Unsubscribe', {
                    method:
                        location.pathname === '/onboarding'
                            ? 'onboarding'
                            : 'explore',
                    publisher_name: newsletter.name,
                    publisher_id: id,
                });
            } else {
                await subscribeForNewsletter(userId, id);

                setButtonClasses([
                    ...buttonClasses,
                    'explore-card-subscribed-button-isSubscribed',
                ]);
                setSubscribed(true);

                //analytics
                sendAmplitudeData('Subscribe', {
                    method:
                        location.pathname === '/onboarding'
                            ? 'onboarding'
                            : 'explore',
                    publisher_name: newsletter.name,
                    publisher_id: id,
                });

                trackWithPixel('Subscribe', { userId, newsletterId: id });
            }
        } catch (error) {
            console.log(error.message);
        }
    };
    return (
        <div className='explore-card-bottom-part'>
            {article && (
                <div className='latest-article-wrapper'>
                    <LatestArticle
                        title={textCutter(article.title, 55)}
                        id={article._id}
                        handleClick={handleChangeLastEntrance}
                        userId={userId}
                        setModal={setModal}
                        isPublic={isPublic}
                        disabledLink={disabledLink}
                    />
                </div>
            )}

            <div className='explore-card-details'>
                {isPublic ? (
                    <CardButton
                        handleSubscribe={handleSubscribe}
                        buttonClasses={buttonClasses}
                        subscribed={subscribed}
                    />
                ) : (
                        <p></p>
                    )}
                {!location.pathname.includes('/onboarding') ? <ArrowRight /> : null}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userId: getUserId(state),
    userNewsletters: getUserNewsletters(state),
});

const mapDispatchToProps = {
    subscribeForNewsletter,
    unsubscribeFromNewsletter,
    lastEntranceSuccess,
    removeNewsletterFromFolderSuccess,
};
export default connect(mapStateToProps, mapDispatchToProps)(CardArticles);
