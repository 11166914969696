export const OPEN_SIDE_MENU = `OPEN_SIDE_MENU`;
export const CLOSE_SIDE_MENU = `CLOSE_SIDE_MENU`;
export const OPEN_INPUT_CREATE_FOLDER = `OPEN_INPUT_CREATE_FOLDER`;
export const CLOSE_INPUT_CREATE_FOLDER = `CLOSE_INPUT_CREATE_FOLDER`;
export const OPEN_MINI_NEWSLETTERS = `OPEN_MINI_NEWSLETTERS`;
export const CLOSE_MINI_NEWSLETTERS = `CLOSE_MINI_NEWSLETTERS`;
export const OPEN_LOAD_MORE_NEWSLETTERS = `OPEN_LOAD_MORE_NEWSLETTERS`;
export const CLOSE_LOAD_MORE_NEWSLETTERS = `CLOSE_LOAD_MORE_NEWSLETTERS`;
export const OPEN_NEWSLETTERS_FOLDER = `OPEN_NEWSLETTERS_FOLDER`;
export const CLOSE_NEWSLETTERS_FOLDER = `CLOSE_NEWSLETTERS_FOLDER`;
export const OPEN_SPECIFIC_FOLDER = `OPEN_SPECIFIC_FOLDER`;
export const CLOSE_SPECIFIC_FOLDER = `CLOSE_SPECIFIC_FOLDER`;
export const OPEN_FEEDBACK_MODAL = 'OPEN_FEEDBACK_MODAL';
export const CLOSE_FEEDBACK_MODAL = 'CLOSE_FEEDBACK_MODAL';
export const OPEN_PREMIUM_FEATURES_MODAL = 'OPEN_PREMIUM_FEATURES_MODAL';
export const CLOSE_PREMIUM_FEATURES_MODAL = 'CLOSE_PREMIUM_FEATURES_MODAL';