import moment from 'moment';

export const dateProvider = () => {
    const currentLocalDate = moment().format('L');
    const newCurrLocalDate = currentLocalDate.replaceAll('/', '-');
    return newCurrLocalDate;
};

export const hourArticleProvider = (datePublished) => {
    const now = moment(new Date()); //todays date
    const stillUtc = moment.utc(datePublished).toDate();
    const local = moment(stillUtc).local().format();
    const duration = moment.duration(now.diff(local));
    const hours = duration.asHours();

    if (Math.floor(hours) <= 1) {
        return '1 hour ago';
    } else if (hours < 24) {
        return `${Math.floor(hours)} hours ago`;
    } else if (hours >= 24 && hours <= 48) {
        return `${Math.floor(hours / 24)} day ago`;
    } else {
        return `${Math.floor(hours / 24)} days ago`;
    }
};

export const hourArticleProviderShort = (datePublished) => {
    const now = moment(new Date()); //todays date
    const stillUtc = moment.utc(datePublished).toDate();
    const local = moment(stillUtc).local().format();
    const duration = moment.duration(now.diff(local));
    const hours = duration.asHours();

    if (Math.floor(hours) <= 1) {
        return '1h ago';
    } else if (hours < 24) {
        return `${Math.floor(hours)}h ago`;
    } else if (hours >= 24 && hours <= 48) {
        return `${Math.floor(hours / 24)}d ago`;
    } else {
        return `${Math.floor(hours / 24)}d ago`;
    }
};

export const transformDate = (date) => {
    return moment(date).format('MMMM Do YYYY, h:mm:ss a');
};
