import MainHeading from '../../shared/MainHeading';
import NewsletterInfoSection from './NewsletterInfoSection';
import { connect } from 'react-redux';
import {
    clearCurrentNewsletter,
    fetchNewsletterInfo,
} from '../../../actions/newsletterActions';
import { removeFromUnseen } from '../../../actions/userActions';
import { getCurrentNewsletter } from '../../../reducers/newsletterReducer';
import { getUserId, getUserUnseen } from '../../../reducers/userReducer';
import { useEffect, useState } from 'react';
import { sendAmplitudeData } from '../../../utils/amplitude';
import AllArticles from './AllArticles';
import UnreadArticles from './UnreadArticles';
import { getFromLS } from '../../../utils/storage';
import { useHistory } from 'react-router-dom';
import LoaderMaterialUI from '../../shared/LoaderMaterialUI';
import './Newsletter.scss';

const Newsletter = ({
    clearCurrentNewsletter,
    match,
    newsletter,
    userId,
    removeFromUnseen,
    fetchNewsletterInfo,
}) => {
    const { newsletterId } = match.params;
    const [amplitude, setAmplitude] = useState(true);
    const [filter, setFilter] = useState(false);
    const [isSubscribedMain, setIsSubscribedMain] = useState(false);
    const history = useHistory();

    if (!getFromLS('role')) {
        history.push('/');
    }

    useEffect(() => {
        if (amplitude) {
            sendAmplitudeData('Page Viewed', {
                path: window.location.pathname,
                title: `Myletterbox - Newsletter`,
                url: window.location.href,
            });
            setAmplitude(false);
        }

        const fetchData = async () => {
            await fetchNewsletterInfo(newsletterId);
        };

        fetchData();

        return () => {
            clearCurrentNewsletter();
        };
    }, [newsletterId]);

    useEffect(() => {
        userId && removeFromUnseen({ userId, newsletterId: newsletterId });
    }, [userId, newsletterId]);

    const handleFilterOff = () => {
        if (filter) {
            setFilter(false);
        }
    };

    const handleFilterOn = () => {
        if (!filter) {
            setFilter(true);
        }
    };

    return (
        <div className='newsletter-main-section-wrapper'>
            {newsletter._id ? (
                <>
                    <MainHeading
                        wrapperClassName='main-heading-onboard-wrapper'
                        className='normal-heading'
                    >
                        {newsletter.name}
                    </MainHeading>
                    <div className='newsletter-main-section'>
                        <NewsletterInfoSection
                            id={newsletterId}
                            logo={newsletter.logo}
                            description={newsletter.description}
                            name={newsletter.name}
                            articles={newsletter.articles}
                            setIsSubscribedMain={setIsSubscribedMain}
                        />
                        <div className='articles-wrapper'>
                            <div className='articles-wrapper-filter'>
                                <p
                                    className={filter ? '' : 'active-filter'}
                                    onClick={handleFilterOff}
                                >
                                    All
                                </p>
                                {isSubscribedMain ? (
                                    <p
                                        className={
                                            filter ? 'active-filter' : ''
                                        }
                                        onClick={handleFilterOn}
                                    >
                                        Unread
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        {filter ? (
                            <UnreadArticles newsletterId={newsletterId} />
                        ) : (
                            <AllArticles newsletterId={newsletterId} />
                        )}
                    </div>
                </>
            ) : (
                <LoaderMaterialUI />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    newsletter: getCurrentNewsletter(state),
    userId: getUserId(state),
    userUnseen: getUserUnseen(state),
});

const mapDispatchToProps = {
    clearCurrentNewsletter,
    removeFromUnseen,
    fetchNewsletterInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Newsletter);
