import CheckIn from '../../layouts/CheckIn';
import { ReactComponent as PageLogo } from '../assets/page-laptop-girl-logo.svg';
import FormNewPassword from './FormNewPassword';
import './EnterNewPassword.scss';

const EnterNewPassword = (props) => {
    return (
        <CheckIn>
            <div className='new-password-central-section-wrapper'>
                <div className='new-password-central-section'>
                    <PageLogo className='new-password-picture-pages' />
                    <FormNewPassword query={props.location.search} className='form-new-password' />
                </div>
            </div>
        </CheckIn>
    );
};

export default EnterNewPassword;
