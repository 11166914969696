import { useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { showAlternativeLogo } from '../../../../../helpers/showAlternativeLogo';
import { getFromLS, setToLS } from '../../../../../utils/storage';
import './MiniNewsletters.scss';

const MiniNewsletter = ({ newsletter, index }) => {
    const history = useHistory();
    const currId = history.location.pathname.split('/').pop();

    return (
        <Link
            to={'/newsletters/' + newsletter._id}
            key={newsletter._id}
            // ref={ref}
        >
            <div className='mini-logo-wrapper'>
                {newsletter.logo ? (
                    <img
                        src={newsletter.logo}
                        alt='L'
                        className={`${
                            currId === newsletter._id
                                ? 'inside-newsletter-mini-icon'
                                : undefined
                        } ${
                            newsletter.isPublic
                                ? 'mini-logo'
                                : 'private-logo mini-logo'
                        }`}
                        onError={showAlternativeLogo}
                    />
                ) : (
                    <p>{newsletter.name[0].toUpperCase()}</p>
                )}
            </div>
        </Link>
    );
};

export default MiniNewsletter;
