import Button from '../../../shared/Button';
import InputField from '../../../shared/InputField';
import MainHeading from '../../../shared/MainHeading';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import useForm from '../../../../hooks/useForm';
import { validateUsername } from '../../../../helpers/validators';
import { createUsernameOnExistingUser } from '../../../../actions/userActions';
import { getUserId } from '../../../../reducers/userReducer';
import { trackWithPixel } from '../../../../utils/facebook-pixel';
import './FormUsername.scss';

const FormUsername = ({ userId, createUsernameOnExistingUser }) => {
    const [values, setValues] = useForm({ username: '' });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            validateUsername(values.username.trim());
            await createUsernameOnExistingUser(userId, values.username.trim());
            trackWithPixel('Create username', { userId, username: values.username.trim() })
            history.push('/latest');
        } catch (error) {
            setLoading(false);
            setError(error.error || error.message);
        }
    };

    return (
        <div className='form-username-wrapper'>
            <form className='form-username' onSubmit={handleSubmit}>
                <div>
                    <MainHeading>Choose your nickname</MainHeading>
                    <h3>We’ll create a unique email address for you.
                    Use it to subscribe for newsletters and they’ll
                        show up in Letterbox.</h3>
                    <InputField
                        placeholder='Username'
                        type='text'
                        defaultPlaceholder='Username'
                        signature='@myletterbox.io'
                        value={values.username}
                        handler={setValues}
                        name='username'
                        error={error}
                    />
                </div>
                {loading ? (
                    <Button disabled>LOADING...</Button>
                ) : (
                        <Button arrow={true}>Register</Button>
                    )}
            </form>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userId: getUserId(state),
});

const mapDispatchToProps = {
    createUsernameOnExistingUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormUsername);
