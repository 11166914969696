import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { closeSideMenu } from '../../../../actions/sideMenuActions';
import './HeaderPages.scss';
import { useEffect, useState } from 'react';

const HeaderPages = ({ closeSideMenu }) => {
    const locationToUse = useLocation();
    const [location, setLocation] = useState([]);

    useEffect(() => {
        if (locationToUse && locationToUse.pathname) {
            const locationArray = locationToUse.pathname
                .split('/')
                .filter((w) => w !== '');
            setLocation(locationArray);
        }
    }, [closeSideMenu, locationToUse.key]);

    const handleCloseMenu = () => {
        const vw = Math.max(
            document.documentElement.clientWidth || 0,
            window.innerWidth || 0
        );

        if (vw <= 839) {
            closeSideMenu();
        }
    };

    return (
        <div className='header-pages'>
            <Link to='/latest' onClick={handleCloseMenu}>
                <div
                    className={`${
                        location.includes('latest') ? 'active' : ''
                    } sidebar-link-wrapper`}
                >
                    <span className='header-link-to-page sibebar-inner-elements'>
                        Latest
                    </span>
                </div>
            </Link>
            <Link to='/explore' onClick={handleCloseMenu}>
                <div
                    className={`${
                        location.includes('explore') ? 'active' : ''
                    } sidebar-link-wrapper explore-small-div`}
                >
                    <span className='header-link-to-page sibebar-inner-elements'>
                        Explore
                    </span>
                </div>
            </Link>
            <Link to='/favorites' onClick={handleCloseMenu}>
                <div
                    className={`${
                        location.includes('favorites') ? 'active' : ''
                    } sidebar-link-wrapper`}
                >
                    <span className='header-link-to-page sibebar-inner-elements'>
                        Favorites
                    </span>
                </div>
            </Link>
        </div>
    );
};

const mapDispatchToProps = {
    closeSideMenu,
};

export default connect(null, mapDispatchToProps)(HeaderPages);
