import { ReactComponent as DefaultProfilePicture } from './assets/default-profile-picture.svg';
import ProfileButton from '../ProfileButton';
import { connect } from 'react-redux';
import { updatePhoto } from '../../../../actions/userActions';
import { getUserPhoto, getUserId } from '../../../../reducers/userReducer';
import { useState, useEffect, useRef } from 'react';
import { storage } from '../../../../utils/firebase';
import './ProfilePicture.scss';

async function paramsOfCanvas(fileName, event) {
    const canvas = document.createElement('canvas');
    const MAX_WIDTH = 600;

    const scaleSize = MAX_WIDTH / event.target.width;
    canvas.width = MAX_WIDTH;
    canvas.height = event.target.height * scaleSize;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(event.target, 0, 0, canvas.width, canvas.height);

    const srcEncoded = ctx.canvas.toDataURL(event.target, 'image/jpeg', 0.8);
    let blob = await (await fetch(srcEncoded)).blob();

    let fileToReturn = new File([blob], fileName, {
        lastModified: new Date().getTime(),
        type: blob.type,
    });
    return fileToReturn;
}

const ProfilePicture = ({ photoURL, updatePhoto, userId }) => {
    const [divText, setDivText] = useState('Choose File');
    const [currentPhotoURL, setCurrentPhotoURL] = useState('');
    const [previousPhotoURL, setPreviousPhotoURL] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        setCurrentPhotoURL(photoURL);
    }, [photoURL, userId]);

    const handleChange = async (e) => {
        if (e.target.files[0]) {
            try {
                setPreviousPhotoURL(currentPhotoURL);
                setDivText('Uploading ...');

                const file = e.target.files[0];
                let photoStorageUrl = null;

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.addEventListener('load', async (e) => {
                    const imgElements = document.createElement('img');
                    imgElements.src = e.target.result;

                    imgElements.addEventListener('load', async (event) => {
                        let newFile = file;

                        if (file.size > 600000) {
                            newFile = await paramsOfCanvas(file.name, event);
                        }

                        let fileName = newFile.name + Math.random();
                        let storageRef = storage.ref('photos/' + fileName);

                        await storageRef.put(newFile);
                        photoStorageUrl = await storageRef.getDownloadURL();

                        try {
                            if (currentPhotoURL) {
                                const previousPhotoRef =
                                    storage.refFromURL(currentPhotoURL);

                                await previousPhotoRef.delete();
                            }
                        } catch (e) {
                            console.log(e.code || e.error);
                        }

                        setCurrentPhotoURL(photoStorageUrl);

                        await updatePhoto(userId, photoStorageUrl);
                        setDivText(file.name);
                    });
                });
            } catch (e) {
                console.log(e);
            }
        } else {
            setDivText('Choose File');
        }
    };

    const handleRemovePhoto = async () => {
        try {
            inputRef.current.value = '';

            try {
                if (currentPhotoURL) {
                    const previousPhotoRef =
                        storage.refFromURL(currentPhotoURL);
                    await previousPhotoRef.delete();
                }
            } catch (e) {
                console.log(e.code || e.error);
            }

            setDivText('Choose File');
            setCurrentPhotoURL('');
            setPreviousPhotoURL('');
            await updatePhoto(userId, '');
        } catch (error) {
            console.log(error.code || error.error);
        }
    };

    return (
        <div className='profile-picture-wrapper'>
            <div className='profile-icon-wrapper'>
                {currentPhotoURL ? (
                    <img src={currentPhotoURL} alt='no-pic'></img>
                ) : (
                    <DefaultProfilePicture />
                )}
            </div>
            <div className='profile-buttons-wrapper'>
                <div className='profile-upload-wrapper'>
                    <span>{divText}</span>
                    <input
                        ref={inputRef}
                        type='file'
                        id='image-profile'
                        name='image'
                        className='profile-upload'
                        onChange={handleChange}
                    />
                </div>
                <ProfileButton
                    className='black'
                    handleClick={handleRemovePhoto}
                >
                    Remove Image
                </ProfileButton>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    photoURL: getUserPhoto(state),
    userId: getUserId(state),
});

const mapDispatchToProps = {
    updatePhoto,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePicture);
