import React from 'react';
import { FiAlignCenter } from 'react-icons/fi';
import './EmptyStateArticles.scss';

export default function EmptyStateArticles({textContent}) {
    return (
        <div className='empty-state-articles-div'>
            <FiAlignCenter className='empty-state-articles-icon' />
            <p className='empty-state-articles-text'>{textContent}</p>
        </div>
    );
}
