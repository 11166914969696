import { HTTP } from '../constants/index';
import { auth } from '../utils/firebase';

const request = async (url, method, data) => {
    const options = {
        method,
    };

    let token = true;
    if (method !== 'GET') {
        token = await auth.currentUser?.getIdToken(true);
    } else {
        if (
            url.split('/')[4] ===
            'users?_id=true&isBlocked=true&username=true&email=true&dateRegistration=true&role=true'
        ) {
            token = await auth.currentUser?.getIdToken(true);
        }
    }

    if (token) {
        options.headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        };
    } else {
        options.headers = {
            'Content-Type': 'application/json',
        };
    }

    if (data) {
        options.body = JSON.stringify({ ...data });
    }

    return fetch(url, options);
};

export default {
    get: (url, data) => request(url, HTTP.GET, data),
    post: (url, data) => request(url, HTTP.POST, data),
    delete: (url, data) => request(url, HTTP.DELETE, data),
    put: (url, data) => request(url, HTTP.PUT, data),
    patch: (url, data) => request(url, HTTP.PATCH, data),
};
