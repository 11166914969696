const environment = process.env.NODE_ENV
    ? process.env.NODE_ENV.trim()
    : 'production';

const reactEnv = process.env.REACT_APP_ENV
    ? process.env.REACT_APP_ENV.trim()
    : 'production';

// const environment = 'development';
// const reactEnv = 'development';

console.log('react env: ', reactEnv);
console.log('environment: ', environment);

const serverAddress = {
    development: 'https://myletterbox.io/api',
    production: 'https://myletterbox.io/api',
    staging: 'https://myletterbox.io/api',
};

const amplitudeAPI = {
    development: 'b369ebf486f4617f08f80a30d232b592',
    production: 'b8a491c7d6517e7131243abe3ff1cae2',
    staging: 'b369ebf486f4617f08f80a30d232b592',
};

const stripe = {
    development: {
        publicKey:
            'pk_test_51J28aMGRXR7ojb1koWNIHDPMsXrR1LRy8R2SaLSiekkwJh5BxeEShl3HHr8yIjcBg5gJ6fOz3eY8hwlYk1vudJ2l00a79spDc9',
    },
    production: {
        publicKey:
            'pk_live_51J28aMGRXR7ojb1kwqZKXzikQpWMZ37fzn7VWyBT450G0oEL1rTmlK3RpMGFfyzjvZCkjAIKXtaDizqae1CQpMnB00IFsrmtPd',
    },
    staging: {
        publicKey:
            'pk_test_51J28aMGRXR7ojb1koWNIHDPMsXrR1LRy8R2SaLSiekkwJh5BxeEShl3HHr8yIjcBg5gJ6fOz3eY8hwlYk1vudJ2l00a79spDc9',
    },
};

export const BASE_URL = serverAddress[reactEnv || environment];
export const AMPLITUDE_API = amplitudeAPI[reactEnv || environment];
export const STRIPE_CONFIG = stripe[reactEnv || environment];
