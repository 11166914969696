import { useEffect } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { clearFavoritesArticles } from '../../../../actions/articleActions';
import { clearDailyNewsletters } from '../../../../actions/newsletterActions';
import {
    fetchAllCategories,
    changePositions,
} from '../../../../actions/categoryActions';
import { getAllCategories } from '../../../../reducers/categoryReducer';

import FormNewCategory from './FormNewCategory';
import SingleCategory from './SingleCategory';

import './CategoriesList.scss';

const CategoriesList = ({
    clearFavoritesArticles,
    clearDailyNewsletters,
    setNotification,
    categories,
    fetchAllCategories,
    changePositions,
}) => {
    useEffect(() => {
        clearFavoritesArticles();
        clearDailyNewsletters();
    }, []);

    useEffect(() => {
        fetchAllCategories();
    }, [fetchAllCategories]);

    async function onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        await changePositions(result.source.index, result.destination.index);
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className='admin-categories-wrapper'>
                <FormNewCategory setNotification={setNotification} />
                <Droppable droppableId='list'>
                    {(provided) => (
                        <div
                            className='admin-categories'
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            <h2>Categories</h2>

                            {categories.map((c, i) => (
                                <SingleCategory
                                    key={c._id}
                                    index={i}
                                    name={c.name}
                                    categoryId={c._id}
                                    setNotification={setNotification}
                                />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </DragDropContext>
    );
};

const mapStateToProps = (state) => ({
    categories: getAllCategories(state),
});

const mapDispatchToProps = {
    clearFavoritesArticles,
    clearDailyNewsletters,
    fetchAllCategories,
    changePositions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesList);
