import { useEffect } from 'react';
import { connect } from 'react-redux';

import { getPopularNewsletters } from '../../../../reducers/newsletterReducer';
import {
    fetchPopularNewsletters,
    clearPopularNewsletters,
} from '../../../../actions/newsletterActions';

import ExploreCard from '../../../shared/ExploreCard';
import '../Cards.scss';

const PopularCards = ({
    fetchPopularNewsletters,
    clearPopularNewsletters,
    popularNewsletters,
}) => {
    useEffect(() => {
        fetchPopularNewsletters();

        return () => {
            clearPopularNewsletters();
        };
    }, [fetchPopularNewsletters]);

    return (
        <div className='onboarding-cards-wrapper'>
            <h2>Popular</h2>
            <div className='onboarding-cards'>
                {popularNewsletters.map((x) => (
                    <ExploreCard
                        newsletter={x}
                        description={x.description}
                        articles={x.articles}
                        id={x._id}
                        key={x._id}
                    />
                ))}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    popularNewsletters: getPopularNewsletters(state),
});

const mapDispatchToProps = {
    fetchPopularNewsletters,
    clearPopularNewsletters,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopularCards);
