import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getFromLS } from '../utils/storage';

function PrivateRoute({ component: Component, ...children }) {
    if (getFromLS('role')) {
        return (
            <Route {...children} render={(props) => <Component {...props} />} />
        );
    } else {
        return <Redirect to='/' />;
    }
}

export default PrivateRoute;
