import {
    FETCH_ALL_CATEGORIES,
    CREATE_CATEGORY,
    DELETE_CATEGORY,
    EDIT_CATEGORY,
    CHANGE_POSITIONS,
    FETCH_NEWSLETTERS_BY_CATEGORY,
} from '../actionTypes/categoryTypes';
import categoryService from '../services/categoryService';
import newslettersService from '../services/newslettersService';

const fetchAllCategoriesSuccess = (data) => ({
    type: FETCH_ALL_CATEGORIES,
    payload: data,
});

const createNewCategorySuccess = (data) => ({
    type: CREATE_CATEGORY,
    payload: data,
});

const deleteCategorySuccess = (categoryId) => ({
    type: DELETE_CATEGORY,
    payload: categoryId,
});

const editCategorySuccess = (data) => ({
    type: EDIT_CATEGORY,
    payload: data,
    id: data._id,
});

const changePositionsSuccess = (startIndex, endIndex) => ({
    type: CHANGE_POSITIONS,
    payload: { startIndex, endIndex },
});

const fetchNewslettersByCategorySuccess = (data, categoryId) => ({
    type: FETCH_NEWSLETTERS_BY_CATEGORY,
    payload: data,
    categoryId: categoryId,
});

export const fetchAllCategories = () => async (dispatch) => {
    const response = await categoryService.findAllCategories();
    const data = await response.json();

    if (data.error) {
        throw { error: data.error };
    }

    dispatch(fetchAllCategoriesSuccess(data));
};

export const createNewCategory = (categoryName) => async (dispatch) => {
    try {
        const response = await categoryService.createNewCategory(categoryName);
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(createNewCategorySuccess(data));
    } catch (err) {
        throw { error: err.error };
    }
};

export const deleteCategory = (categoryId) => async (dispatch) => {
    try {
        const response = await categoryService.deleteCategory(categoryId);
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(deleteCategorySuccess(categoryId));
    } catch (err) {
        throw { error: err.error };
    }
};

export const editCategory = (categoryId, categoryInfo) => async (dispatch) => {
    try {
        const response = await categoryService.editCategory(
            categoryId,
            categoryInfo
        );
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(editCategorySuccess(data));
    } catch (err) {
        throw { error: err.error };
    }
};

export const changePositions = (startIndex, endIndex) => async (dispatch) => {
    try {
        dispatch(changePositionsSuccess(startIndex, endIndex));
        const response = await categoryService.changePositions(
            startIndex,
            endIndex
        );
        const data = await response.json();

        if (data.error) {
            throw {
                error: data.error,
            };
        }
    } catch (err) {
        throw { error: err.error };
    }
};

export const fetchNewslettersByCategory = (categoryId) => async (dispatch) => {
    try {
        const response = await newslettersService.findNewslettersByCategory(
            categoryId
        );
        const data = await response.json();

        if (data.error) {
            throw {
                error: data.error,
            };
        }

        dispatch(fetchNewslettersByCategorySuccess(data, categoryId));
    } catch (err) {
        throw { error: err.error };
    }
};
