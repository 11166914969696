import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import './LatestArticle.scss';

const LatestArticle = ({
    title,
    id,
    userId,
    setModal,
    isPublic,
    disabledLink,
}) => {
    const [articleClicked, setArticleClicked] = useState(false);

    const handleArticleClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!userId) {
            setModal(true);
        } else {
            if (!disabledLink) {
                setArticleClicked(true);
            }
        }
    };

    return (
        <>
            {isPublic ? (
                articleClicked ? (
                    <Redirect to={`/articles/${id}`} />
                ) : (
                    <p
                        className='latest-article-explore-card'
                        onClick={handleArticleClick}
                    >
                        {title}{' '}
                    </p>
                )
            ) : (
                <p
                    className='latest-article-explore-card'
                    onClick={handleArticleClick}
                >
                    {title}{' '}
                </p>
            )}
        </>
    );
};
export default LatestArticle;
