import FormPublishNewsletter from '../FormPublishNewsletter';
import { deleteNewsletter,fetchNewsletter } from '../../../../../actions/newsletterActions';
import { getCurrentNewsletter } from '../../../../../reducers/newsletterReducer';
import { connect } from 'react-redux';
import { notifyUser } from '../../../../../helpers/notifications';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { transformDate } from '../../../../../helpers/dateProvider';
import { showAlternativeLogo } from '../../../../../helpers/showAlternativeLogo';
import moment from 'moment'
import './UnpublishedCard.scss';
import EditNewsletterFormUnpublished from '../EditNewsletterFormUnpublished';

const UnpublishedCard = ({
    id,
    emails,
    name,
    logo,
    subscribers,
    articles,
    deleteNewsletter,
    setNotification,
    newsletter,
    setRender,
    description,
    fetchNewsletter
}) => {
    const [showForm, setShowForm] = useState(false);
    const [editForm, setEditForm] = useState(false);

    const handleEditOpen = async () => {
        if (editForm) {
            setEditForm(false);
        } else {
            await fetchNewsletter(id, 1);
            setEditForm(true);
        }
    };

    const handleDelete = () => {
        try {
            deleteNewsletter(id);
            notifyUser('success', 'Deleted successfully!', setNotification);
        } catch (e) {
            notifyUser('error', e.error, setNotification);
        }
    };

    const handleEdit = () => {
        try {
            // deleteNewsletter(id);
            notifyUser('success', 'Edited successfully!', setNotification);
        } catch (e) {
            notifyUser('error', e.error, setNotification);
        }
    };

    return (
        <>
            <div className='unpublished-card-wrapper'>
                <img
                    src={logo}
                    alt={name}
                    onError={showAlternativeLogo}
                    className='logo-admin-panel-unpublished'
                />
                <p>
                    Newsletter: <span>{name}</span>
                </p>
                <p>
                    Emails:
                    <select>
                        {emails.map((e, i) => (
                            <option key={i}>{e}</option>
                        ))}
                    </select>
                </p>
                <p>
                    Description: <span>{description}</span>

                </p>
                <p>
                    Subscribers: <span>{subscribers.length}</span>
                </p>
                <p>Articles:</p>
                <div className='unpublished-articles-wrapper'>
                    {articles.map((a) => (
                        <li key={a._id}>
                            <Link to={`/articles/${a._id}`}>
                                {a.title} - {transformDate(a.date)}
                            </Link>
                        </li>
                    ))}
                </div>

                {/* <button
                    className='unpublished-button-delete unpublished-button'
                    onClick={handleDelete}
                >
                    Delete
                </button> */}
                
                <button
                    className='unpublished-button-delete unpublished-button'
                    onClick={handleEditOpen}
                >
                    Edit
                </button>
                {editForm && (
                <EditNewsletterFormUnpublished
                    email={emails}
                    newsletter={newsletter}
                    setNotification={setNotification}
                    setEditForm={setEditForm}
                    id={id}
                    setRender={setRender}
                />
            )}
            </div>
           
        </>
    );
};

const mapStateToProps = (state) => ({
    newsletter: getCurrentNewsletter(state),
});

const mapDispatchToProps = {
    fetchNewsletter,
    deleteNewsletter,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnpublishedCard);
