import { useEffect, useState } from 'react';
import { checkSmtpStatus } from '../../../../actions/userActions';
import './InformationForAdmin.scss';

const InformationForAdmin = () => {
    const [status, setStatus] = useState(true);

    const handleCheckSmtp = async () => {
        const newStatus = await checkSmtpStatus();
        if (newStatus) {
            setStatus(true);
        } else {
            setStatus(false);
        }
    };

    useEffect(() => {
        async function checkStatusSmtp() {
            const newStatus = await checkSmtpStatus();
            if (newStatus) {
                setStatus(true);
            } else {
                setStatus(false);
            }
        }

        checkStatusSmtp();
    }, []);
    return (
        <div className='admin-info-wrapper'>
            <h2>
                Email:{' '}
                <span className='admin-email'>
                    admin@myletterbox.io
                </span>
            </h2>
            <p className='status-of-smtp'>
                Status of smtp server:{' '}
                <span
                    className={`${!status && 'status-alone-down'} status-alone`}
                >
                    {status ? 'ok' : 'down'}
                </span>
            </p>
            <button className='button-check-smtp' onClick={handleCheckSmtp}>
                Check mail server
            </button>
        </div>
    );
};

export default InformationForAdmin;
