import Category from './Category';
import { useLocation, NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getAllCategories } from '../../../reducers/categoryReducer';
import { fetchAllCategories } from '../../../actions/categoryActions';
import './Categories.scss';

const Categories = ({ fetchAllCategories, categories }) => {
    let { pathname } = useLocation();
    const [_, mainPath, subpath] = useLocation().pathname.split('/');
    let isNotCollections = pathname !== '/explore/collections';

    useEffect(() => {
        isNotCollections && fetchAllCategories();
    }, [pathname, fetchAllCategories]);

    return (
        <div className='categories-section-wrapper'>
            <ul>
                <NavLink
                    className={subpath ? 'unselected' : 'selected'}
                    to={`/${mainPath}`}
                >
                    <li className='category-box-wrapper'>
                        <span>All</span>
                    </li>
                </NavLink>
                {isNotCollections ? (
                    categories.map((x) => (
                        <Category key={x._id} textContent={x.name} />
                    ))
                ) : (
                    <li>All</li>
                )}
            </ul>
        </div>
    );
};

const mapStateToProps = (state) => ({
    categories: getAllCategories(state),
});

const mapDispatchToProps = {
    fetchAllCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
