import { combineReducers } from 'redux';
import {
    FETCH_SINGLE_ARTICLE,
    REMOVE_SINGLE_ARTICLE,
    FETCH_FAVORITE_ARTICLES,
    CLEAR_FAVORITES_ARTICLE,
    LAST_ENTRANCE,
    FETCH_NEXT_ARTICLE,
    REMOVE_NEXT_ARTICLE,
    FETCH_SEARCH_CONTENT_MATCH,
    CLEAR_SEARCH_CONTENT_MATCH
} from '../actionTypes/articleTypes';

const articleInitialState = {
    _id: '',
    date: '',
    title: '',
    content: '',
    clearContent: '',
    clearContentShort: '',
    newsletter: {
        _id: '',
        name: '',
    },
};

const current = (state = articleInitialState, action = {}) => {
    switch (action.type) {
        case REMOVE_SINGLE_ARTICLE:
            return articleInitialState;
        case FETCH_SINGLE_ARTICLE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

const next = (state = articleInitialState, action = {}) => {
    switch (action.type) {
        case REMOVE_NEXT_ARTICLE:
            return articleInitialState;
        case FETCH_NEXT_ARTICLE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

const handleMoreFavorites = (state, newArticles) => {
    const newState = { ...state };
    Object.keys(newArticles).forEach((x) => {
        if (!newState[x]) {
            newState[x] = [];
        }
        newState[x].push(...newArticles[x]);
    });
    return newState;
};

const favorites = (state = {}, action = {}) => {
    switch (action.type) {
        case FETCH_FAVORITE_ARTICLES:
            return handleMoreFavorites(state, action.payload);
        case CLEAR_FAVORITES_ARTICLE:
            return {};
        default:
            return state;
    }
};

const handleSearchContent = (state, newArticles) => {
    const newArticlesState = newArticles.map(a=>({...articleInitialState,...a}));
    const newState = [...state,...newArticlesState]
    return newState;
};

const searchContent = (state = [], action = {}) => {
    switch (action.type) {
        case FETCH_SEARCH_CONTENT_MATCH:
            return handleSearchContent(state, action.payload);
        case CLEAR_SEARCH_CONTENT_MATCH:
            return [];   
        default:
            return state;
    }
};

const lastEntrance = (state = '/explore', action = {}) => {
    switch (action.type) {
        case LAST_ENTRANCE:
            return action.payload;
        default:
            return state;
    }
};

export default combineReducers({
    current,
    next,
    favorites,
    searchContent,
    lastEntrance
});

export const getFavoriteArticles = (state) => state.articles.favorites;
export const getSearchArticles = (state) => state.articles.searchContent;
export const getArticle = (state) => state.articles.current;
export const getNextArticle = (state) => state.articles.next;
export const getLastEntrance = (state) => state.articles.lastEntrance
