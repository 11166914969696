import { ReactComponent as Logo } from './assets/new-logo.svg';
import { Link } from 'react-router-dom';
import React from 'react';
import './CheckIn.scss';

const CheckIn = ({ children, noBackground }) => {

    const handleClickToLanding = () => {
        window.location.replace('https://myletterbox.io')
    }

    return (
        <div className={`${noBackground && 'no-background'} check-in`}>
            <div className='check-in-wrapper'>
                <header>
                    <Logo className='header-logo' onClick={handleClickToLanding} />
                </header>
                <div className='check-in-main'>{children}</div>
                <footer>
                    <div>
                        <span>Copyright letterbox.io</span>
                    </div>
                    <ul>
                        <li>
                            <Link to='/terms-and-conditions'>
                                Terms & Conditions
                            </Link>
                        </li>
                        <li>
                            <Link to='/privacy-policy'>Privacy Policy</Link>
                        </li>
                    </ul>
                </footer>
            </div>
        </div>
    );
};

export default CheckIn;
