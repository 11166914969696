import { categoryApi } from './api';
import requester from '../services/requester';

export default {
    findAllCategories: () => requester.get(categoryApi.findAllCategories()),
    createNewCategory: (categoryName) =>
        requester.post(categoryApi.createNewCategory(), {
            name: categoryName,
        }),
    deleteCategory: (categoryId) =>
        requester.delete(categoryApi.deleteCategory(categoryId)),
    editCategory: (categoryId, categoryInfo) =>
        requester.put(categoryApi.editCategory(categoryId), {
            ...categoryInfo,
        }),
    changePositions: (startIndex, endIndex) =>
        requester.put(categoryApi.changePositions(), { startIndex, endIndex }),
};
