import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { getCurrentNewsletter } from '../../../../reducers/newsletterReducer';
import { getUserId, getUserUnseen } from '../../../../reducers/userReducer';
import { textCutterRemoveHTML } from '../../../../helpers/textCutter';
import LoadMoreSinglePages from '../../../shared/LoadMoreSinglePages';
import {
    fetchUnreadSpecificNewsletter,
    clearCurrentNewsletterArticles,
} from '../../../../actions/newsletterActions';
import { removeFromUnseen } from '../../../../actions/userActions';
import ArticleLineInfo from '../../../shared/ArticleLineInfo';

const UnreadArticles = ({
    getCurrentNewsletter,
    getUserId,
    getUserUnseen,
    fetchUnreadSpecificNewsletter,
    newsletter,
    userUnseen,
    newsletterId,
    userId,
    clearCurrentNewsletterArticles,
    removeFromUnseen,
}) => {
    const [hideButton, setHideButton] = useState(true);

    useEffect(() => {
        window.scroll(0, 0);
        const fetchData = async () => {
            const resultArticles = await fetchUnreadSpecificNewsletter(
                userId,
                newsletterId,
                0
            );
            if (resultArticles.length < 6) {
                setHideButton(true);
            } else {
                setHideButton(false);
            }
        };

        fetchData();

        return () => {
            clearCurrentNewsletterArticles();
        };
    }, [getCurrentNewsletter, getUserId, getUserUnseen]);

    const handlePageExpansion = async (e) => {
        try {
            const fetchData = async () => {
                const skip = newsletter.articles.filter((n) => n._id).length;
                const resultArticles = await fetchUnreadSpecificNewsletter(
                    userId,
                    newsletterId,
                    skip
                );
                if (resultArticles.length < 6) {
                    setHideButton(true);
                } else {
                    setHideButton(false);
                }
            };

            fetchData();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className='articles-wrapper-content'>
                {newsletter.articles?.map((a) => (
                    <ArticleLineInfo
                        key={a._id}
                        date={a.date}
                        title={a.title}
                        content={textCutterRemoveHTML(a.clearContentShort, 250)}
                        id={a._id}
                        newsletter={newsletter.name}
                        newsletterId={newsletterId}
                    />
                ))}
            </div>
            <LoadMoreSinglePages
                handler={handlePageExpansion}
                hidden={hideButton}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    newsletter: getCurrentNewsletter(state),
    userId: getUserId(state),
    userUnseen: getUserUnseen(state),
});

const mapDispatchToProps = {
    fetchUnreadSpecificNewsletter,
    removeFromUnseen,
    clearCurrentNewsletterArticles,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnreadArticles);
