import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getUserEmail,
    getUserId,
    getUsername,
} from '../../../../reducers/userReducer';
import {
    updatePassword,
    logout,
    updateName,
    fetchNameOfUser,
} from '../../../../actions/userActions';
import {
    validateEmail,
    validatePassword,
} from '../../../../helpers/validators';
import useForm from '../../../../hooks/useForm';
import ProfileInput from '../ProfileInput';
import ProfileButton from '../ProfileButton';
import { ReactComponent as CopyClipBoard } from './assets/copy-icon.svg';
import { trackWithPixel } from '../../../../utils/facebook-pixel';
import './ProfileForm.scss';

const ProfileForm = ({
    username,
    userEmail,
    userId,
    logout,
    updatePassword,
    updateName,
    fetchNameOfUser,
}) => {
    const [isCopied, setIsCopied] = useState(false);
    const [values, setValues] = useForm({
        currentPassword: '',
        newPassword: '',
    });
    const [nameUser, setNameUser] = useState('');
    const [initialUserName, setInitialUserName] = useState('');

    const [loading, setLoading] = useState(false);
    const [loadedName, setLoadedName] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            const name = await fetchNameOfUser(userId);
            setNameUser(name);
            setInitialUserName(name);
            setLoadedName(true);
        };
        if (userId) {
            fetchData();
        }
    }, [userId]);

    const copyToClipBoard = () => {
        navigator.clipboard.writeText(username);
        setIsCopied(true);
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);

            if (nameUser && nameUser !== initialUserName) {
                await updateName(userId, nameUser);
            }

            if (values.newPassword) {
                validateEmail(userEmail);
                validatePassword(values.currentPassword);
                validatePassword(values.newPassword);
                await updatePassword(
                    userId,
                    values.currentPassword,
                    values.newPassword
                );
                setValues({ currentPassword: '', newPassword: '' });
                trackWithPixel('Customize Product', { userId, userEmail })
                await logout();
                history.push('/');
            }
        } catch (error) {
            setLoading(false);
            setValues({ currentPassword: '', newPassword: '' });
        }

        setLoading(false);
    };

    return (
        <>
            {loadedName ? (
                <div className='profile-form-wrapper'>
                    <form>
                        <ProfileInput
                            isDisabled={true}
                            type='text'
                            value={userEmail}
                            textDisabled={'Account login'}
                        ></ProfileInput>
                        <ProfileInput
                            name='username'
                            type='text'
                            value={nameUser}
                            handleChange={(e) => setNameUser(e.target.value)}
                            placeholder={'Username'}
                        ></ProfileInput>

                        <ProfileInput
                            name='currentPassword'
                            type='password'
                            value={values.currentPassword}
                            handleChange={setValues}
                            placeholder={'Current Password'}
                        ></ProfileInput>
                        <ProfileInput
                            name='newPassword'
                            type='password'
                            value={values.newPassword}
                            handleChange={setValues}
                            placeholder={'New Password'}
                        ></ProfileInput>
                        <ProfileButton
                            handleClick={handleSaveChanges}
                            className='orange'
                            isDisabled={loading}
                        >
                            {loading ? 'Saving Changes...' : 'Save Changes'}
                        </ProfileButton>

                        <div className='username-content'>
                            <h3>Letterbox email</h3>
                            <h4>
                                Use this email to subscribe to newsletters etc.
                            </h4>
                            <div className='user-private-email'>
                                {username && (
                                    <>
                                        <div>
                                            <p>Letterbox email</p>
                                            <p className='user-private-email-value'>
                                                {username}
                                            </p>
                                        </div>
                                        <CopyClipBoard
                                            onClick={copyToClipBoard}
                                        ></CopyClipBoard>
                                    </>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            ) : null}
        </>
    );
};

const mapStateToProps = (state) => ({
    username: getUsername(state),
    userId: getUserId(state),
    userEmail: getUserEmail(state),
});

const mapDispatchToProps = {
    logout,
    updatePassword,
    updateName,
    fetchNameOfUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
