import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { getUserId } from '../../../../../../reducers/userReducer';
import {
    unsubscribeFromNewsletter,
    markAllArticlesFromNewsletterAsReadAndSeen,
} from '../../../../../../actions/userActions';
import { notifyUser } from '../../../../../../helpers/notifications';

import './NewsletterOptions.scss';

const NewsletterOptions = ({
    userId,
    newsletterId,
    unsubscribeFromNewsletter,
    setModal,
    setNotification,
    setHoverable,
    markAllArticlesFromNewsletterAsReadAndSeen,
    folder,
    isPublic,
    userUsername,
    positionY
}) => {
    const optionsRef = useRef(null);

    const onMoveToFolderClick = () => {
        setModal(true);
        setHoverable(false);
    };

    const onUnsubscribeClickHandler = async () => {
        try {
            await unsubscribeFromNewsletter(userId, newsletterId, folder);
            notifyUser('success', 'Unsubscribed successfully', setNotification);
        } catch (error) {
            console.log(error);
        }
    };

    const handleMarkAllAsRead = async () => {
        try {
            await markAllArticlesFromNewsletterAsReadAndSeen(
                userId,
                newsletterId
            );
        } catch (error) {
            console.log(error);
        }
    };

    // useEffect(() => {
    //     optionsRef.current.scrollIntoView({
    //         behavior: 'smooth',
    //         block: 'nearest',
    //         inline: 'nearest',
    //     });
    // }, []);

    return (
        <div className='newsletter-options-wrapper' style={{top:`${positionY || 0}px`}}>
            <div className='newsletter-options' ref={optionsRef}>
                {!isPublic ? (
                    <div className='newsletter-options-button-email-wrapper'>
                        <p className='newsletter-options-button-email'>To: {userUsername}</p>
                    </div>
                ) : null}
                <button
                    className='newsletter-options-button-margin'
                    onClick={handleMarkAllAsRead}
                >
                    Mark all as read
                </button>
                <button
                    className='newsletter-options-button-margin'
                    onClick={onMoveToFolderClick}
                >
                    Move to folder
                </button>
                <button onClick={onUnsubscribeClickHandler}>Unsubscribe</button>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userId: getUserId(state),
});

const mapDispatchToProps = {
    unsubscribeFromNewsletter,
    markAllArticlesFromNewsletterAsReadAndSeen,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterOptions);
