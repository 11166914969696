import './ButtonSocial.scss';

const ButtonSocial = ({
    children,
    handleClick,
    className,
    textContent,
    handler
}) => {
    return (
        <button
            className={`${className} social-button`}
            onClick={handleClick}
        >  {children}
            <span>{textContent}</span>
        </button>
    );
};

export default ButtonSocial;
