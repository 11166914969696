import { BASE_URL } from '../config/config';
const USER_URL = `${BASE_URL}/users`;
const USER_AUTH_URL = `${USER_URL}/auth`;
const CATEGORY_URL = `${BASE_URL}/categories`;
const NEWSLETTER_URL = `${BASE_URL}/newsletters`;
const ARTICLE_URL = `${BASE_URL}/articles`;
const UNPUBLISHED_URL = `${BASE_URL}/unpublished`;
const FEEDBACK_URL = `https://myletterbox.io/api/feedback`;

export const userApi = {
    register: () => `${USER_AUTH_URL}/register`,
    registerWithSocial: () => `${USER_AUTH_URL}/register?social=true`,
    resetPassword: () => `${USER_AUTH_URL}/password?reset=true`,
    updatePassword: () => `${USER_AUTH_URL}/password?update=true`,
    preSubscribe: () => `${USER_URL}/subscribe`,
    findUserInitialInfo: (userId) => `${USER_URL}/${userId}?initial=true`,
    findUserByEmail: (email) => `${USER_URL}/email/${email}?_id=true`,
    findNameOfUser: (userId) => `${USER_URL}/${userId}?name=true`,
    findAllUsersDetails: () =>
        `${USER_URL}?_id=true&isBlocked=true&username=true&email=true&dateRegistration=true&role=true`,
    findUserByUsername: (username) =>
        `${USER_URL}/username/${username}?_id=true&email=true`,
    addArticleToFavorites: (userId) =>
        `${USER_URL}/${userId}/articles?favorites=true`,
    addArticleToRead: (userId) => `${USER_URL}/${userId}/articles?read=true`,
    removeArticleFromFavorites: (userId, articleId) =>
        `${USER_URL}/${userId}/articles/${articleId}?favorites=true`,
    removeArticleFromRead: (userId, articleId) =>
        `${USER_URL}/${userId}/articles/${articleId}?read=true`,
    findUserFavorites: (userId) =>
        `${USER_URL}/${userId}/articles?favorites=true`,
    findUserRead: (userId) => `${USER_URL}/read/${userId}/articles?read=true`,
    findFavoriteArticles: (userId, page) =>
        `${USER_URL}/${userId}/articles?favorites=true&groupBy=newsletter&page=${page}`,
    updatePhoto: (userId) => `${USER_URL}/${userId}`,
    updateName: (userId) => `${USER_URL}/${userId}`,
    changeStatus: (userId) => `${USER_URL}/${userId}`,
    createUsername: (userId) => `${USER_URL}/${userId}`,

    findUserNewsletters: (userId) => `${USER_URL}/${userId}/newsletters`,
    subscribeForNewsletter: (userId) => `${USER_URL}/${userId}/newsletters`,
    unsubscribeFromNewsletter: (userId, newsletterId) =>
        `${USER_URL}/${userId}/newsletters/${newsletterId}`,
    findDaily: (userId, page, date) =>
        `${USER_URL}/${userId}/newsletters?daily=true&date=${date}&page=${page}`,
    findLatestFeed: (userId) => `${USER_URL}/${userId}/newsletters?latest=true`,
    findLatestFeedSingleNewsletter: (userId, newsletterId, skip) =>
        `${USER_URL}/${userId}/newsletters?latest=true&newsletter=${newsletterId}&skip=${skip}`,
    findUnreadSpecificNewsletter: (userId, newsletterId, skip) =>
        `${USER_URL}/${userId}/newsletters/${newsletterId}?unread=true&skip=${skip}`,
    findDailyArticlesIds: (userId, date) =>
        `${USER_URL}/${userId}/newsletters?daily=true&date=${date}&ids=true`,
    findLatestFeedIds: (userId) =>
        `${USER_URL}/${userId}/newsletters?latest=true&ids=true`,
    findDailyCount: (userId, date) =>
        `${USER_URL}/${userId}/newsletters?daily=true&date=${date}&count=true`,
    addFolder: (userId) => `${USER_URL}/${userId}/folders`,
    findFolders: (userId) => `${USER_URL}/${userId}/folders`,
    addNewsletterToUsersFolder: (userId, folderId) =>
        `${USER_URL}/${userId}/folders/${folderId}`,
    returnNewsletterToMainFolder: (userId, folderId) =>
        `${USER_URL}/${userId}/folders/${folderId}?remove=true`,
    addNewsletterToAnotherFolder: (userId, folderId) =>
        `${USER_URL}/${userId}/folders/${folderId}?change=true`,
    changePositionsInFolder: (userId, folderId) =>
        `${USER_URL}/${userId}/folders/${folderId}?positions=true`,
    findNewslettersForFolder: (userId, folderId) =>
        `${USER_URL}/${userId}/folders/${folderId}`,
    checkSmtpStatus: () => `${USER_URL}/smtp-server/status`,
    editFolderName: (userId, folderId) =>
        `${USER_URL}/${userId}/folders/${folderId}`,
    deleteFolder: (userId, folderId) =>
        `${USER_URL}/${userId}/folders/${folderId}`,
    fetchSingleFolder: (folderId) => `${USER_URL}/folders/${folderId}`,
    subscribeToAllNewslettersFromFolder: (userId) =>
        `${USER_URL}/${userId}/newsletters?subscribeAll=true`,
    makeFolderPublic: (userId, folderId) =>
        `${USER_URL}/${userId}/folders/${folderId}?public=true`,
    makeFolderPrivate: (userId, folderId) =>
        `${USER_URL}/${userId}/folders/${folderId}?private=true`,
    findUnseen: (userId) => `${USER_URL}/${userId}?unseen=true`,
    removeFromUnseen: (userId) => `${USER_URL}/${userId}`,
    changePositionsOfNewsletters: (userId) =>
        `${USER_URL}/${userId}/newsletters`,
    fetchNewslettersNotInFolder: (userId) =>
        `${USER_URL}/${userId}/newsletters?notInFolder=true`,
    removeNewsletterFromNewsletterNotInFolder: (userId, newsletterId) =>
        `${USER_URL}/${userId}/newsletters/${newsletterId}`,
    markAllArticlesFromNewsletterAsReadAndSeen: (userId, newsletterId) =>
        `${USER_URL}/${userId}/newsletters/${newsletterId}?markAsRead=true`,
    stripeFetchCheckoutSession: (userId) =>
        `${USER_URL}/${userId}/plan-products`,
    stripeManagePortalUrl: (userId) =>
        `${USER_URL}/${userId}/plan-products/manage`,
    stripeActionNeeded: (userId) =>
        `${USER_URL}/${userId}/plan-products/action`,
    findUserPlan: (userId) => `${USER_URL}/${userId}/plan-products`,
    changeUserPlan: (userId) => `${USER_URL}/${userId}/plan-products/premium`,
};

export const categoryApi = {
    findAllCategories: () => `${CATEGORY_URL}`,
    createNewCategory: () => `${CATEGORY_URL}`,
    deleteCategory: (categoryId) => `${CATEGORY_URL}/${categoryId}`,
    editCategory: (categoryId) => `${CATEGORY_URL}/${categoryId}`,
    changePositions: () => `${CATEGORY_URL}`,
};

export const newsletterApi = {
    findPopular: () => `${NEWSLETTER_URL}?sort=popular`,
    findPrivateNewslettersUser: (userId) =>
        `${NEWSLETTER_URL}?private=true&userId=${userId}`,
    findPopularByCategory: (category) =>
        `${NEWSLETTER_URL}?sort=popular&category=${category}`,
    findLatest: (limit = 3, page = 1) =>
        `${NEWSLETTER_URL}?sort=latest&limit=${limit}&page=${page}`,
    findLatestByCategory: (category, limit = 3, page = 1) =>
        `${NEWSLETTER_URL}?sort=latest&limit=${limit}&page=${page}&category=${category}`,
    createNewNewsletter: () => `${NEWSLETTER_URL}/`,
    findNewsletterById: (id, skip) => `${NEWSLETTER_URL}/${id}?skip=${skip}`,
    findNumberExplorePages: () => `${NEWSLETTER_URL}?count=true`,
    findNumberExploreCategoryPages: (category) =>
        `${NEWSLETTER_URL}?count=true&category=${category}`,
    findNewsletterArticlesCount: (id) =>
        `${NEWSLETTER_URL}/${id}?count=true&articles=true`,
    findAllUnpublishedNewsletters: () => `${NEWSLETTER_URL}?type=unpublished`,
    findAllPublishedNewsletters: () => `${NEWSLETTER_URL}?type=published`,
    editNewsletterInfo: (id) => `${NEWSLETTER_URL}/${id}`,
    editNewslettersPrivateInfo: (id) => `${NEWSLETTER_URL}/${id}?private=true`,
    deleteNewsletter: (id) => `${NEWSLETTER_URL}/${id}`,
    findNewslettersByCategory: (categoryId) =>
        `${NEWSLETTER_URL}/categories/${categoryId}`,
};

export const articleApi = {
    findSingleArticle: (articleId) => `${ARTICLE_URL}/${articleId}`,
    findSearchContentMatch: (userId, query, page) =>
        `${ARTICLE_URL}?search=${query}&page=${page}&userId=${userId}`,
    findSearchContentMatchCount: (userId, query) =>
        `${ARTICLE_URL}?search=${query}&count=true&userId=${userId}`,
    createNewArticle: () => `${ARTICLE_URL}/`,
    uploadArticleFromUser: (userId) =>
        `${USER_URL}/${userId}/articles/customArticle`,
};

export const unpublishedApi = {
    findAllUnpublished: () => `${UNPUBLISHED_URL}`,
    deleteUnpublished: (id) => `${UNPUBLISHED_URL}/${id}`,
};

export const feedbackApi = {
    sendFeedback: () => `${FEEDBACK_URL}/send-feedback`
};
