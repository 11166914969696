import ArticleLineInfo from '../ArticleLineInfo';
import { useEffect, useState } from 'react';
import {
    fetchLatestFeedSingleNewsletter,
    fetchLatestFeedIds,
    fetchLatestFeedNewsletters,
    clearLatestFeedNewslettersSuccess,
} from '../../../actions/newsletterActions';
import { getLatestFeedNewsletters } from '../../../reducers/newsletterReducer';
import { getUserId } from '../../../reducers/userReducer';
import { textCutterRemoveHTML } from '../../../helpers/textCutter';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadMoreSinglePages from '../../shared/LoadMoreSinglePages';
import ArticlesLoader from '../ArticlesLoader';
import EmptyStateOnboardingStyle from '../EmptyStateOnboardingStyle';
import EmptyStateArticles from '../EmptyStateArticles';
import './ListingArticles.scss';

const ListingArticles = ({
    newsletters,
    userId,
    clearLatestFeedNewslettersSuccess,
    fetchLatestFeedIds,
    fetchLatestFeedNewsletters,
    fetchLatestFeedSingleNewsletter,
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    const location = useLocation()
        .pathname.split('/')
        .filter((a) => a.trim() !== '');
    const page =
        location[0] === 'latest'
            ? 'latest'
            : location[0] === 'favorites'
                ? 'favorites'
                : '';

    const [hideButtons, setHideButtons] = useState({});

    const handleLoadMore = async (id) => {
        const result = await fetchLatestFeedSingleNewsletter(
            userId,
            id,
            newsletters.find((o) => o._id === id).articles.length
        );

        if (result.length < 6) {
            const buttonsObject = { ...hideButtons };
            buttonsObject[id] = true;
            setHideButtons(buttonsObject);
        }
    };

    useEffect(() => {
        // clearFavoritesArticles();

        try {
            if (userId) {
                async function fetchCount() {
                    await fetchLatestFeedIds(userId);
                    const newslettersResult = await fetchLatestFeedNewsletters(
                        userId
                    );
                    // setIsLoadingMore(false);
                    const buttonsObject = {};

                    newslettersResult.forEach((n) => {
                        buttonsObject[n._id] =
                            n.articles.length < 6 ? true : false;
                    });

                    setHideButtons(buttonsObject);
                    setIsLoading(false);
                }
                if (newsletters.length == 0) {
                    fetchCount();
                }
            }
        } catch (error) {
            console.log(error);
        }

        return function cleanup() {
            clearLatestFeedNewslettersSuccess();
        };
    }, [
        userId,
        clearLatestFeedNewslettersSuccess,
        fetchLatestFeedIds,
        fetchLatestFeedNewsletters,
    ]);

    return (
        <>
            {isLoading ? (
                <ArticlesLoader />
            ) : newsletters && newsletters.length === 0 ? (
                page === 'latest'
                    ? <EmptyStateOnboardingStyle />
                    :  <EmptyStateArticles textContent={'Everything was read'}/>
            ) : (
                        newsletters.map((n, i) => {
                            return (
                                <div
                                    key={`${n._id}${i}`}
                                    className='newsletters-wrapper'
                                >
                                    <p className='newsletter-name-wrapper'>
                                        <Link
                                            className='newsletter-name'
                                            to={`/newsletters/${n._id}`}
                                        >
                                            {n.name}
                                        </Link>
                                    </p>

                                    <div className='newsletter-articles-content'>
                                        {n.articles.map((ar, i) => (
                                            <ArticleLineInfo
                                                key={`${ar._id}${i}`}
                                                date={ar.date}
                                                title={ar.title}
                                                page={page}
                                                content={textCutterRemoveHTML(
                                                    ar.clearContentShort,
                                                    100
                                                )}
                                                id={ar._id}
                                                newsletter={n.name}
                                                newsletterId={n._id}
                                            />
                                        ))}
                                    </div>
                                    <LoadMoreSinglePages
                                        hidden={hideButtons[n._id]}
                                        handler={handleLoadMore}
                                        id={n._id}
                                    />
                                </div>
                            );
                        })
                    )}
            {isLoadingMore && <ArticlesLoader />}
        </>
    );
};

const mapStateToProps = (state) => ({
    newsletters: getLatestFeedNewsletters(state),
    userId: getUserId(state),
});
const mapDispatchToProps = {
    fetchLatestFeedIds,
    fetchLatestFeedNewsletters,
    fetchLatestFeedSingleNewsletter,
    clearLatestFeedNewslettersSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingArticles);
