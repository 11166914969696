import { ReactComponent as CrossedEye } from './assets/eye-negative.svg';
import { ReactComponent as NotCrossedEye } from './assets/eye-positive.svg';
import { ReactComponent as CrossedEyeWhite } from './assets/white-eye-negative.svg';
import { ReactComponent as NotCrossedEyeWhite } from './assets/white-eye-positive.svg';
import { useState, useRef } from 'react';
import './InputField.scss';

const InputField = ({
    placeholder,
    handler,
    error,
    value,
    defaultPlaceholder,
    type,
    signature,
    name,
    dark,
    id,
    autocomplete
}) => {
    const [inputType, setInputType] = useState(type);
    const inputField = useRef(null);

    const toggleVisibility = (e) => {
        e.preventDefault();
        if (inputType === 'text') {
            setInputType('password');
        } else {
            setInputType('text');
        }
    };

    const focusInput = () => {
        inputField.current.focus();
    }

    return (
        <div
            className={`${error ? 'input-wrapper-error' : ''} ${dark ? 'input-wrapper-dark' : ''
                } input-wrapper`}
            onClick={focusInput}
        >
            {error && <div className='alternative-placeholder'>
                <label
                    htmlFor={id}
                    className={`${error ? 'error-span' : ''} label-span`}
                >
                    {error || placeholder}
                </label>
            </div>}

            <input
                id={id}
                className={`${signature ? 'signature-width-input' : 'full-width-input'
                    } ${type === 'password' ? 'visibility-hidden' : ''}`}
                type={inputType || 'text'}
                name={name}
                placeholder={defaultPlaceholder}
                onChange={handler}
                value={value}
                ref={inputField}
                autoComplete={autocomplete}
            />
            {/* {type === 'password' && (
                <button
                    className='form-control'
                    onClick={toggleVisibility}
                    type='button'
                >
                    {dark ? (
                        inputType === 'password' ? (
                            <NotCrossedEyeWhite className='NotCrossedEyeWhite' />
                        ) : (
                            <CrossedEyeWhite className='CrossedEyeWhite' />
                        )
                    ) : inputType === 'password' ? (
                        <NotCrossedEye className='NotCrossedEye' />
                    ) : (
                        <CrossedEye className='CrossedEye' />
                    )}
                </button>
            )} */}
            {signature && <span className='signature'>{signature}</span>}
        </div>
    );
};

export default InputField;
