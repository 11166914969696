import ArticleLineInfo from '../ArticleLineInfo';
import { useEffect, useState, useRef } from 'react';
import {
    fetchSearchContentMatch,
    clearSearchContentMatch,
    fetchSearchContentMatchCount,
} from '../../../actions/articleActions';
import {
    subscribeForNewsletter,
    unsubscribeFromNewsletter,
} from '../../../actions/userActions';
import { removeNewsletterFromFolderSuccess } from '../../../actions/folderActions';
import { getSearchArticles } from '../../../reducers/articleReducer';
import {
    getUserId,
    getUserNewsletters,
    getUserPlan,
} from '../../../reducers/userReducer';
import { textCutterRemoveHTML } from '../../../helpers/textCutter';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import ArticlesLoader from '../ArticlesLoader';
import LoadMoreSinglePages from '../../shared/LoadMoreSinglePages';
import ArticlesLoaderPremium from '../ArticlesLoaderPremium';
import './ListingArticlesSearch.scss';

const ListingArticlesSearch = ({
    fetchSearchContentMatch,
    userId,
    articles,
    query,
    clearSearchContentMatch,
    newsletters,
    fetchSearchContentMatchCount,
    subscribeForNewsletter,
    unsubscribeFromNewsletter,
    removeNewsletterFromFolderSuccess,
    userPlan,
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [isPremium, setIsPremium] = useState('BASE');
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        setIsPremium(userPlan === 'PREMIUM');
        clearSearchContentMatch();
        setIsLoading(true);
        try {
            if (userId) {
                async function fetchCount() {
                    await fetchSearchContentMatch(
                        userId,
                        query,
                        currPage.current
                    );
                    setIsLoading(false);
                    const count = await fetchSearchContentMatchCount(
                        userId,
                        query
                    );
                    setTotalCount(count);
                    allPages.current = Math.ceil(count / 10);
                    if (allPages.current - 1 <= currPage.current) {
                        setHideButton(true);
                    } else {
                        setTimeout(() => setHideButton(false), 1000);
                    }
                }
                fetchCount();
            }
        } catch (error) {
            console.log(error);
        }
    }, [fetchSearchContentMatch, userId, query, userPlan, getUserPlan]);

    const [hideButton, setHideButton] = useState(true);
    const currPage = useRef(0);
    const allPages = useRef(0);

    const handleLoadMore = async () => {
        setIsLoadingMore(true);
        currPage.current += 1;
        await fetchSearchContentMatch(userId, query, currPage.current);
        setIsLoadingMore(false);
        if (allPages.current - 1 === currPage.current) {
            setHideButton(true);
        }
    };

    const handleSubscribe = async (id) => {
        await subscribeForNewsletter(userId, id);
    };

    const handleUnSubscribe = async (id) => {
        await unsubscribeFromNewsletter(userId, id);
        await removeNewsletterFromFolderSuccess(id);
    };

    const userNewslettersIds = newsletters.map((n) => n._id);

    return (
        <>
            {isLoading ? (
                <ArticlesLoader />
            ) : articles ? (
                isPremium ? (
                    articles.map((ar, i) => {
                        return (
                            <div
                                className='newsletters-wrapper'
                                key={`${ar.newsletter._id}${i}`}
                            >
                                <p className='newsletter-name-wrapper'>
                                    <Link
                                        className='newsletter-name'
                                        to={`/newsletters/${ar.newsletter._id}`}
                                    >
                                        {ar.newsletter.name}
                                    </Link>

                                    {userNewslettersIds.includes(
                                        ar.newsletter._id
                                    ) ? (
                                            <span
                                                className='button-unsubscribe-newsletter'
                                                onClick={() =>
                                                    handleUnSubscribe(
                                                        ar.newsletter._id
                                                    )
                                                }
                                            >
                                                Subscribed
                                            </span>
                                        ) : (
                                            <span
                                                className='button-subscribe-newsletter'
                                                onClick={() =>
                                                    handleSubscribe(
                                                        ar.newsletter._id
                                                    )
                                                }
                                            >
                                                Subscribe
                                            </span>
                                        )}
                                </p>

                                <div className='newsletter-articles-content'>
                                    <ArticleLineInfo
                                        date={ar.date}
                                        title={ar.title}
                                        content={textCutterRemoveHTML(
                                            ar.clearContent,
                                            120
                                        )}
                                        id={ar._id}
                                        key={ar._id}
                                        newsletter={ar.newsletter.name}
                                        newsletterId={ar.newsletter._id}
                                    />
                                </div>
                            </div>
                        );
                    })
                ) : (
                        articles.slice(0, 2).map((ar, i) => {
                            return (
                                <div
                                    className='newsletters-wrapper'
                                    key={`${ar.newsletter._id}${i}`}
                                >
                                    <p className='newsletter-name-wrapper'>
                                        <Link
                                            className='newsletter-name'
                                            to={`/newsletters/${ar.newsletter._id}`}
                                        >
                                            {ar.newsletter.name}
                                        </Link>

                                        {userNewslettersIds.includes(
                                            ar.newsletter._id
                                        ) ? (
                                                <span
                                                    className='button-unsubscribe-newsletter'
                                                    onClick={() =>
                                                        handleUnSubscribe(
                                                            ar.newsletter._id
                                                        )
                                                    }
                                                >
                                                    Subscribed
                                                </span>
                                            ) : (
                                                <span
                                                    className='button-subscribe-newsletter'
                                                    onClick={() =>
                                                        handleSubscribe(
                                                            ar.newsletter._id
                                                        )
                                                    }
                                                >
                                                    Subscribe
                                                </span>
                                            )}
                                    </p>

                                    <div className='newsletter-articles-content'>
                                        <ArticleLineInfo
                                            date={ar.date}
                                            title={ar.title}
                                            content={textCutterRemoveHTML(
                                                ar.clearContent,
                                                120
                                            )}
                                            id={ar._id}
                                            key={ar._id}
                                            newsletter={ar.newsletter.name}
                                            newsletterId={ar.newsletter._id}
                                        />
                                    </div>
                                </div>
                            );
                        })
                    )
            ) : null}
            {isLoadingMore && <ArticlesLoader />}
            {isPremium ? (
                !query.includes('subscribed')
                    ? <LoadMoreSinglePages
                        hidden={hideButton}
                        handler={handleLoadMore}
                    />
                    : null
            ) : totalCount > 2 ? (
                <>
                    <LoadMoreSinglePages
                        hidden={true}
                        handler={handleLoadMore}
                    />
                    <div className='hidden-article-search'>
                        <ArticlesLoaderPremium />
                        <div className='hidden-article-search-mask'>
                            <p>
                                View all {totalCount} results by subscribing to
                                our{' '}
                                <Link to={'/profile'}>Premium Membership</Link>
                            </p>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};

const mapStateToProps = (state) => ({
    articles: getSearchArticles(state),
    userId: getUserId(state),
    newsletters: getUserNewsletters(state),
    userPlan: getUserPlan(state),
});
const mapDispatchToProps = {
    fetchSearchContentMatch,
    clearSearchContentMatch,
    fetchSearchContentMatchCount,
    subscribeForNewsletter,
    unsubscribeFromNewsletter,
    removeNewsletterFromFolderSuccess,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListingArticlesSearch);
