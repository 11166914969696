import { useState, useEffect, React } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getFromLS, setToLS } from '../../../utils/storage';

import { closeSideMenu } from '../../../actions/sideMenuActions';
import { fetchUserUnseen, fetchUserLocationChange } from '../../../actions/userActions';
import { fetchNewslettersNotInFolder } from '../../../actions/newsletterActions';
import { getUserId, getUserEmail } from '../../../reducers/userReducer';

import Header from '../../shared/Header';
import SideMenu from '../../shared/SideMenu';
import FeedbackModal from '../../shared/FeedbackModal';
import PremiumFeaturesModal from '../../shared/PremiumFeaturesModal';
import NotificationUser from '../../shared/NotificationUser';
import PhoneMenu from '../../shared/PhoneMenu/PhoneMenu';
import SideMenuMobile from '../../shared/SideMenuMobile/SideMenuMobile';

import './LayoutMain.scss';

const LayoutMain = ({
    children,
    backLink,
    closeSideMenu,
    opened,
    backLinkUrl,
    userId,
    userEmail,
    fetchUserUnseen,
    fetchNewslettersNotInFolder,
    fetchUserLocationChange,
    feedbackModalOpened,
    premiumFeaturesModalOpened,
}) => {
    const [settingsMenu, setVisibilitySettings] = useState(false);
    const [theme, setTheme] = useState(getFromLS('theme'));
    const [light, setLight] = useState(theme === 'light');
    const [notification, setNotification] = useState({ type: '', message: '' });
    const [backLinkToShow, setBackLinkToShow] = useState(false);

    const location = useLocation()
        .pathname.split('/')
        .filter((a) => a.trim() !== '');

    const locationForDataFetching = useLocation();

    if (!backLinkToShow && location.includes('articles')) {
        setBackLinkToShow(true);
    } else if (backLinkToShow && !location.includes('articles')) {
        setBackLinkToShow(false);
    }

    useEffect(() => {
        window.scroll(0, 0);
        setTheme(getFromLS('theme'));

        userId && fetchUserLocationChange(userId);
        userId && fetchUserUnseen(userId);
        userId && fetchNewslettersNotInFolder(userId);

    }, [light, backLink, locationForDataFetching.key]);

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "//code.tidio.co/knyx49fdcqk0xmthro0hpuz2youlyim3.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    useEffect(() => {
        async function fetchUserNewsletter() {
            if (userId) {
                await fetchNewslettersNotInFolder(userId);
                // await fetchUserUnseen(userId);
            }
        }

        fetchUserNewsletter();
    }, [userId]);

    useEffect(() => {
        document.tidioIdentify = {
            distinct_id: userId,
            email: userEmail
        };
    }, [userId, userEmail]);

    const handleToggleTheme = () => {
        light ? setToLS('theme', 'dark') : setToLS('theme', 'light');
        light ? setLight(false) : setLight(true);
    };

    const toggleSettings = () => {
        settingsMenu
            ? setVisibilitySettings(false)
            : setVisibilitySettings(true);
    };

    return (
        <div
            className={`${theme || ''} layout-main-wrapper ${opened ? 'layout-main-mobile-open-menu' : ''
                } `}
        >
            <NotificationUser notification={notification} />
            <Header backLink={backLinkToShow} backLinkUrl={backLinkUrl} location={location} />
            <PhoneMenu backLink={backLinkToShow} backLinkUrl={backLinkUrl} />

            <FeedbackModal isOpen={feedbackModalOpened} />

            <PremiumFeaturesModal isOpen={premiumFeaturesModalOpened} />

            <SideMenu
                setNotification={setNotification}
                menuSettingsClick={toggleSettings}
                settingsMenu={settingsMenu}
                handleToggleTheme={handleToggleTheme}
                light={light}
                setVisibilitySettings={setVisibilitySettings}
            />

            <SideMenuMobile
                setNotification={setNotification}
                menuSettingsClick={toggleSettings}
                settingsMenu={settingsMenu}
                handleToggleTheme={handleToggleTheme}
                light={light}
                setVisibilitySettings={setVisibilitySettings}
            />
            {children}
        </div>
    );
};

const mapStateToProps = (state) => ({
    opened: state.sideMenu.opened,
    feedbackModalOpened: state.sideMenu.feedbackModalOpened,
    premiumFeaturesModalOpened: state.sideMenu.premiumFeaturesModalOpened,
    userId: getUserId(state),
    userEmail: getUserEmail(state)
});

const mapDispatchToProps = {
    closeSideMenu,
    fetchUserUnseen,
    fetchNewslettersNotInFolder,
    fetchUserLocationChange
};
export default connect(mapStateToProps, mapDispatchToProps)(LayoutMain);
