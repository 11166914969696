import { ReactComponent as SuccessMark } from './assets/SuccessMark.svg';
import './NotificationUser.scss';

const NotificationUser = ({ notification }) => {
    return notification.message ? (
        <div className={`notification-wrapper-user ${notification.type}`}>
            <div className='notification-user-content-wrapper'>
                <div className='inner-content-notification-user'>
                    {notification.type === 'success' && <SuccessMark />}
                    <span>{notification.message}</span>
                </div>
            </div>
        </div>
    ) : null;
};

export default NotificationUser;
