import './PublicFolderModalGuest.scss';
import { Link } from 'react-router-dom';
const PublicFolderModalGuest = ({ setModal }) => {
    const handleModalClose = (e) => {
        if (
            e.target.className === 'modal-public-folder-guest' ||
            e.target.className === 'close-modal-guest'
        ) {
            setModal(false);
        }
    };
    return (
        <div className='modal-public-folder-guest' onClick={handleModalClose}>
            <div className='modal-content-public-folder-guest'>
                <div className='modal-button-close-public-folder-guest'>
                    <p onClick={handleModalClose} className='close-modal-guest'>
                        Close
                    </p>
                </div>
                <div className='modal-header-public-folder-guest'>
                    <h4 className='modal-title-public-folder-guest'>OOOOPS!</h4>
                </div>
                <div className='modal-body-public-folder-guest'>
                    <p>Looks like you are not in your profile!</p>
                    <p>To subscribe to newsletters you have to login first!</p>
                </div>
                <div className='redirecting-field-guest'>
                    <p>
                        Go and register or login quickly, don't miss anything!
                    </p>
                    <Link to='/'>Click here to Sign in/Sign up</Link>
                </div>
            </div>
        </div>
    );
};

export default PublicFolderModalGuest;
