import { ReactComponent as ReadIcon } from './assets/read-icon.svg';
import { ReactComponent as FavoriteIcon } from './assets/favorite-icon.svg';
import { ReactComponent as ReadIconFill } from './assets/read-icon-fill.svg';
import { ReactComponent as FavoriteIconFill } from './assets/favorite-icon-fill.svg';
import { ReactComponent as GoToArticleIcon } from './assets/go-to-article-icon.svg';
import { Link, useLocation } from 'react-router-dom';
import { hourArticleProvider } from '../../../helpers/dateProvider';
import {
    getUserId,
    getUserFavorites,
    getUserReadArticles,
} from '../../../reducers/userReducer';
import {
    addArticleToFavorites,
    removeArticleFromFavorites,
    addArticleToRead,
    removeArticleFromRead,
} from '../../../actions/userActions';
import { lastEntranceSuccess } from '../../../actions/articleActions';
import { connect } from 'react-redux';
import { useState } from 'react';
import './ArticleLineInfo.scss';

const ArticleLineInfo = ({
    date,
    title,
    content,
    id,
    addArticleToFavorites,
    removeArticleFromFavorites,
    addArticleToRead,
    removeArticleFromRead,
    userId,
    favorites,
    read,
    page,
    newsletter,
    lastEntranceSuccess,
    newsletterId,
}) => {
    const location = useLocation();
    const [favoriteIconClassHelper, setFavoriteIconClassHelper] = useState('');

    const addToFavorites = async () => {
        try {
            addArticleToFavorites(userId, id);
        } catch (error) {
            console.log(error.code || error.error);
        }
    };

    const removeFromFavorites = async () => {
        try {
            removeArticleFromFavorites(userId, id);
        } catch (error) {
            console.log(error.code || error.error);
        }
    };

    const addToRead = async () => {
        try {
            addArticleToRead(userId, id);
        } catch (error) {
            console.log(error.code || error.error);
        }
    };

    const removeFromRead = async () => {
        try {
            removeArticleFromRead(userId, id);
        } catch (error) {
            console.log(error.code || error.error);
        }
    };

    const handleChangeLastEntrance = () => {
        let entry = location.pathname;
        
        if (location.search) {
            entry = entry + location.search;
        }

        lastEntranceSuccess(entry);
    };

    const readArticle = read.includes(id);

    return (
        <article className='article-info-wrapper'>
            <div className='newsletter-for-mobile'>
                <p>
                    <Link to={`/newsletters/${newsletterId}`}>
                        {newsletter}
                    </Link>
                </p>
            </div>
            <div className='article-content'>
                <Link
                    onClick={handleChangeLastEntrance}
                    to={page ? `${page}/articles/${id}` : `/articles/${id}`}
                >
                    <div className='article-title-wrapper'>
                        <span
                            className={`${
                                readArticle && 'article-info-read'
                            } article-info-main`}
                        >
                            {title}
                        </span>
                    </div>
                    <div className='article-info-content'>
                        <p>{content}</p>
                    </div>
                </Link>
            </div>
            <div className='article-info-actions'>
                <div className='article-info-reader'>
                    {favorites.find((f) => f.article === id) ? (
                        <div className='favorite-icon-fill-article-wrapper'>
                            <FavoriteIconFill
                                className={`favorite-icon-fill-article icon-action ${favoriteIconClassHelper}`}
                                onClick={removeFromFavorites}
                            />
                            <span className='tooltiptext tooltiptext-favorites-fill'>
                                Remove From Favorites
                            </span>
                        </div>
                    ) : (
                        <div className='favorite-icon-article-wrapper'>
                            <FavoriteIcon
                                className={`favorite-icon-article icon-action ${favoriteIconClassHelper}`}
                                onClick={addToFavorites}
                            />
                            <span className='tooltiptext tooltiptext-favorites'>
                                Add To Favorites
                            </span>
                        </div>
                    )}
                    {readArticle ? (
                        <div
                            className='read-icon-fill-article-wrapper'
                            onMouseEnter={() =>
                                setFavoriteIconClassHelper('hovered')
                            }
                            onMouseLeave={() => setFavoriteIconClassHelper('')}
                        >
                            <ReadIconFill
                                className='read-icon-fill-article icon-action'
                                onClick={removeFromRead}
                            />
                            <span className='tooltiptext tooltiptext-read-fill'>
                                Mark As Unread
                            </span>
                        </div>
                    ) : (
                        <div
                            className='read-icon-article-wrapper'
                            onMouseEnter={() =>
                                setFavoriteIconClassHelper('hovered')
                            }
                            onMouseLeave={() => setFavoriteIconClassHelper('')}
                        >
                            <ReadIcon
                                className='read-icon-article icon-action'
                                onClick={addToRead}
                            />
                            <span className='tooltiptext tooltiptext-read'>
                                Mark As Read
                            </span>
                        </div>
                    )}
                </div>
                <div className='article-info-time-wrapper'>
                    <p
                        className={`${
                            readArticle && 'article-info-time-read'
                        } article-info-time article-info-time-phone`}
                    >
                        {hourArticleProvider(date)}
                    </p>
                    <GoToArticleIcon className='go-to-article-icon' />
                </div>
            </div>
        </article>
    );
};

const mapStateToProps = (state) => ({
    userId: getUserId(state),
    favorites: getUserFavorites(state),
    read: getUserReadArticles(state),
});

const mapDispatchToProps = {
    addArticleToFavorites,
    removeArticleFromFavorites,
    addArticleToRead,
    removeArticleFromRead,
    lastEntranceSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleLineInfo);
