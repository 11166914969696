import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './SortItems.scss';

const SortItems = ({ modifyQuery }) => {
    const [sortType, setSortType] = useState('');

    const query = useLocation().search;

    useEffect(() => {
        if (query.includes('recent')) {
            setSortType('most recent first');
        } else if (query.includes('oldest')) {
            setSortType('oldest first');
        }
    }, [query]);

    const handleSelectSortType = (e) => {
        const target = e.currentTarget.textContent.toLowerCase();

        if (sortType === target) {
            modifyQuery('', target);
            setSortType('');
        } else {
            modifyQuery(target, sortType);
            setSortType(target);
        }

    }

    return <div className="sort-filters-wrapper">
        <p className="filter-text">Sort by:</p>
        <ul className="search-filters-list">
            <li className={`search-filters-list-item ${sortType === 'most recent first' && 'selected'}`}
                onClick={handleSelectSortType}
            >Most recent first</li>
            <li className={`search-filters-list-item ${sortType === 'oldest first' && 'selected'}`}
                onClick={handleSelectSortType}
            >Oldest first</li>
        </ul>
    </div>
};

export default SortItems;