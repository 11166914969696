import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    getUserRead,
    getUserFolders,
} from '../../../../../reducers/userReducer';
import { closeSideMenu } from '../../../../../actions/sideMenuActions';
import NewslettersInFolderRegularNewsletters from './NewslettersInFolderRegularNewsletters';
import './NewslettersInFolderRegular.scss';
import { useLocation } from 'react-router-dom';
import { getArticle } from '../../../../../reducers/articleReducer';

const NewslettersInFolderRegular = ({
    name,
    read,
    folder,
    closeSideMenu,
    folders,
    setNotification,
    article,
}) => {
    const [newsletters, setNewsletters] = useState([]);
    const [hoverable, setHoverable] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [currentNewsletter, setCurrentNewsletter] = useState('');
    const [emptyText, setEmptyText] = useState('');

    const locationToUse = useLocation()
        .pathname.split('/')
        .filter((l) => l.trim() !== '');

    useEffect(() => {
        if (
            !locationToUse.includes('newsletters') &&
            !locationToUse.includes('articles')
        ) {
            setCurrentNewsletter('');
        } else if (
            !locationToUse.includes('articles') &&
            !folder?.newsletters.find((n) => n._id === locationToUse[1])
        ) {
            setCurrentNewsletter('');
        } else if (locationToUse.includes('articles') && article._id) {
            const articleNewsletterId = article.newsletter._id;

            if (
                !folder?.newsletters.find((n) => n._id === articleNewsletterId)
            ) {
                setCurrentNewsletter('');
            }
        }
    }, [locationToUse, article]);

    useEffect(() => {
        setNewsletters(folder?.newsletters);
        setLoaded(true);


        if (!newsletters || newsletters.length === 0) {
            setTimeout(()=>{
                setEmptyText("The folder is empty. You can drag and drop a newsletter");
            },100);
            
        }

    }, [name, folder, folders]);

    const handleCloseMenu = () => {
        const vw = Math.max(
            document.documentElement.clientWidth || 0,
            window.innerWidth || 0
        );

        if (vw <= 839) {
            closeSideMenu();
        }
    };

    return (
        <>
            <div
                className={`${
                    folder && folder._id && folder.newsletters.length < 3
                        ? 'regular-folder-shorter'
                        : ''
                } newsletters-in-folder regular-folder`}
            >
                {loaded ? (
                    newsletters && newsletters.length !== 0 ? (
                        newsletters.map((x, i) => (
                            <NewslettersInFolderRegularNewsletters
                                handleCloseMenu={handleCloseMenu}
                                newsletter={x}
                                key={`${x._id}${i}`}
                                isPublic={x.isPublic}
                                index={i}
                                setNotification={setNotification}
                                hoverable={hoverable}
                                setHoverable={setHoverable}
                                folder={folder}
                                currentNewsletter={currentNewsletter}
                                setCurrentNewsletter={setCurrentNewsletter}
                            />
                        ))
                    ) : (
                        <div className='empty-folder'>
                            <p>{emptyText}</p>
                        </div>
                    )
                ) : null}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    read: getUserRead(state),
    folders: getUserFolders(state),
    article: getArticle(state),
});

const mapDispatchToProps = {
    closeSideMenu,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewslettersInFolderRegular);
