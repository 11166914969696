import React from 'react';
import ReactDOM, { hydrate, render } from 'react-dom';
import App from './App';
import store from './utils/store';
import { Provider } from 'react-redux';
import '../src/utils/firebase';
import './index.scss';

const rootElement = document.getElementById('root');

render(
    <Provider store={store}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Provider>,
    rootElement
);
