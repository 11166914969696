import CheckIn from '../../layouts/CheckIn';
import FormUsername from './FormUsername';
import { ReactComponent as UsernamePicture } from '../assets/white-logo.svg';
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './OnboardingUsername.scss';
import { getUsername } from '../../../reducers/userReducer';
import { Redirect, useHistory } from 'react-router-dom';

const OnboardingUsername = ({ username }) => {
    const [name, setName] = useState(username && username.includes('@myletterbox.io'));

    if (username && username.includes('@myletterbox.io')) {
        window.history.pushState(
            { urlPath: '/choose-username' },
            '',
            '/choose-username'
        );
    }

    useEffect(() => {
        setName(username && username.includes('@myletterbox.io'));
    }, [username]);

    return name ? (
        <Redirect to='/onboarding' />
    ) : (
            <div className='username-page-wrapper'>
                <CheckIn noBackground={true}>
                    <div className='onboarding-username-central-section-wrapper'>
                        <div className='onboarding-username-central-section'>
                            <FormUsername className='form-username' />
                            <UsernamePicture className='onboarding-username-picture-pages' />
                        </div>
                    </div>
                </CheckIn>
            </div>
        );
};

const mapStateToProps = (state) => ({
    username: getUsername(state),
});

export default connect(mapStateToProps, null)(OnboardingUsername);
