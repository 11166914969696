import DOMPurify from 'dompurify';

export const textCutter = (text, maxLength) => {
    if (!text) {
        return text;
    }

    let shorterText = text.substr(0, maxLength);

    if (text.length > shorterText.length) {
        shorterText = shorterText.substr(
            0,
            Math.min(shorterText.length, shorterText.lastIndexOf(' '))
        );

        shorterText = shorterText + '...';
    }
    return shorterText;
};

export const textCutterForFoldersAndNewslettrsInFolders = (text, maxLength) => {
    if (!text) {
        return text;
    }

    let shorterText = text;

    if (shorterText.length > maxLength) {
        shorterText = shorterText.substr(0, maxLength);

        shorterText = shorterText + '...';
    }
    return shorterText;
};

export const textCutterRemoveHTML = (text, maxLength) => {
    // const strippedString = text.replace(/(<([^>]+)>)/gi, '');
    const strippedStringBeforeRegex = DOMPurify.sanitize(text, {
        ALLOWED_TAGS: [],
    });

    const strippedString = strippedStringBeforeRegex.replaceAll(
        /(&nbsp;|&gt;|&amp;|&quot;|&apos;|&cent;|&pound;|&yen;|&euro;|&copy;|&reg;)/gm,
        ''
    );

    if (!maxLength) {
        return strippedString;
    }

    let shorterText = strippedString.trim().substr(0, maxLength);

    if (text.length > shorterText.length) {
        shorterText = shorterText.substr(
            0,
            Math.min(shorterText.length, shorterText.lastIndexOf(' '))
        );

        shorterText = shorterText + '...';
    }
    return shorterText;
};
