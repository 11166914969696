import ExploreCard from '../../../shared/ExploreCard';
import HeadingCards from '../HeadingCards';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLatestNewsletters } from '../../../../reducers/newsletterReducer';
import {
    fetchLatestNewslettersInitial,
    filterLatestNewslettersInitial,
} from '../../../../actions/newsletterActions';
import { prettifySubPath } from '../../../../helpers/textModifier';
import './LatestCards.scss';
import { getAllCategories } from '../../../../reducers/categoryReducer';

const LatestCards = ({
    newsletters,
    fetchLatestNewslettersInitial,
    filterLatestNewslettersInitial,
    setModal,
    categories
}) => {
    let { pathname } = useLocation();
    let category = prettifySubPath(pathname.split('/').pop());
    const limit = 8;

    useEffect(() => {
        async function fetchLatest() {
            try {
                if (pathname === '/explore') {
                    await fetchLatestNewslettersInitial(limit)
                } else {
                    if (categories.length) {
                        category = categories.filter(
                            (c) =>
                                c.name.toLocaleLowerCase() ===
                                category.toLocaleLowerCase()
                        )[0].name;
                        await filterLatestNewslettersInitial(category, limit, 1);
                    }
                }                    
            } catch (error) {
                console.log(error);
            }
        }

        fetchLatest();
    }, [
        fetchLatestNewslettersInitial,
        filterLatestNewslettersInitial,
        pathname,
        category,
        categories
    ]);

    return (
        <div className='latest-cards-wrapper'>
            <HeadingCards>Popular</HeadingCards>
            <div className='explore-cards-wrapper'>
                {newsletters.map((x) => (
                    <ExploreCard
                        category={x.category.name}
                        newsletter={x}
                        description={x.description}
                        articles={x.articles}
                        id={x._id}
                        key={x._id}
                        setModal={setModal}
                    />
                ))}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    newsletters: getLatestNewsletters(state),
    categories: getAllCategories(state),
});

const mapDispatchToProps = {
    filterLatestNewslettersInitial,
    fetchLatestNewslettersInitial,
};

export default connect(mapStateToProps, mapDispatchToProps)(LatestCards);
