import InputField from '../../../shared/InputField';
import Button from '../../../shared/Button';
import MainHeading from '../../../shared/MainHeading';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useForm from '../../../../hooks/useForm';
import userService from '../../../../services/userService';
import { validatePassword } from '../../../../helpers/validators';

import './FormNewPassword.scss';

const FormNewPassword = ({ query }) => {
    const [values, setValues] = useForm({ password: '' });
    const [passwordError, setPasswordReset] = useState('');
    const [actionCode, setActionCode] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const actionCode = query.substring(
            query.indexOf('oobCode=') + 8,
            query.indexOf('&apiKey')
        );

        const handleCode = async () => {
            try {
                const emailToUpdate = await userService.verifyPasswordResetCode(
                    actionCode
                );
                setEmail(emailToUpdate);
                setActionCode(actionCode);
            } catch (err) {
                history.push('/forgotten-password');
            }
        };

        handleCode();
    }, [query, history]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setPasswordReset('');
        setValues({ password: '' });
        try {
            setLoading(true);
            validatePassword(values.password.trim());
            await userService.confirmPasswordReset(email, values.password.trim());
            history.push('/');
        } catch (error) {
            console.log(error);
            setLoading(false);
            setPasswordReset(error.code || error.error);
        }
    };

    return (
        <div className='form-new-password-wrapper'>
            <div className='form-new-password-content'>
                <MainHeading>New Password</MainHeading>
                <form className='form-new-password' onSubmit={handleSubmit}>
                    <h3>Change your password</h3>
                    <InputField
                        placeholder='New password'
                        defaultPlaceholder='Your password...'
                        value={values.password}
                        handler={setValues}
                        name='password'
                        type='password'
                        id='new-password'
                        error={passwordError}
                    />
                    {loading ? (
                        <Button disabled>LOADING...</Button>
                    ) : (
                        <Button>CHANGE PASSWORD</Button>
                    )}
                </form>
            </div>
        </div>
    );
};

export default FormNewPassword;
