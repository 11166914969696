import { useDrop } from 'react-dnd';
import { ItemTypes } from '../../../../../constants';
import { connect } from 'react-redux';
import { addNewsletterToUsersFolder } from '../../../../../actions/folderActions';
import { getUserFolders, getUserId } from '../../../../../reducers/userReducer';
import { ReactComponent as FolderOptionsIcon } from './assets/OptionsFolder.svg';
import { ReactComponent as ArrowFolder } from '../../assets/Arrow-closed-folder.svg';
import NewslettersInFolderRegular from '../NewslettersInFolderRegular';
import { setFolderAbbriviation } from '../../../../../helpers/setFolderAbbriviation';
import { textCutterForFoldersAndNewslettrsInFolders } from '../../../../../helpers/textCutter';
import { useEffect, useRef, useState } from 'react';
import FolderOptions from '../FolderOptions/FolderOptions';
import ModalPublicFolder from '../ModalPublicFolder';
import { notifyUser } from '../../../../../helpers/notifications';
import NotificationUser from '../../../NotificationUser/NotificationUser';
import {
    closeSpecificFolder,
    openSpecificFolder,
} from '../../../../../actions/sideMenuActions';
import { Droppable } from 'react-beautiful-dnd';
import { getFoldersOpen } from '../../../../../reducers/sideMenuReducer';
import './Folder.scss';


const Folder = ({
    children,
    addNewsletterToUsersFolder,
    index,
    folderId,
    userId,
    folders,
    options,
    setFolderInfo,
    setNotification,
    handle,
    setHoverable,
    hoverable,
    closeSpecificFolder,
    openSpecificFolder,
    foldersOpen
}) => {
    const [folder, setFolder] = useState(null);
    const [optionsCurrent, setOptionsCurrent] = useState(false);
    const [modal, setModal] = useState(false);
    const [isOpened, setOpen] = useState(foldersOpen[folderId]);
    const [positionYOptions, setPositionYOptions] = useState(0);

    useEffect(() => {



        const folderCurrent = folders.find((x) => x.name === children);

        // if (folderCurrent.newsletters.length === 0) {
        //     handleOpen(null, index, folderId);
        // }

        setFolder(folderCurrent);
        return () => setOptionsCurrent(false);
    }, [children, folders,foldersOpen,getFoldersOpen]);

    const handleOpen = async (e) => {
        try {
            if (isOpened) {
                closeSpecificFolder(folderId);
                setOpen(false);
            } else {
                openSpecificFolder(folderId);
                setOpen(true);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleOptions = (e) => {
        const cursorY = e.target.getBoundingClientRect().top;

        if (optionsCurrent) {
            setOptionsCurrent(false);
            setPositionYOptions(0);
        } else {
            e.target.focus();
            setOptionsCurrent(true);
            setPositionYOptions(cursorY + 25);
        }
    };
    const handleOptionsClose = (e) => {
        const time = setInterval(function () {
            setOptionsCurrent(false);
            clearInterval(time);
        }, 200);
    };

    return (
        <Droppable droppableId={`${folderId}`}>
            {(provided, snapshot) => {
                return (
                    <div
                        className={`${
                            snapshot.isDraggingOver ? 'over-folder' : undefined
                        } ${
                            hoverable ? 'hoverable' : undefined
                        } single-folder-wrapper-newsletters`}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        <div>
                            <div
                                className={
                                    isOpened
                                        ? 'single-folder-wrapper opened-folder'
                                        : 'single-folder-wrapper'
                                }
                            >
                                {modal && (
                                    <ModalPublicFolder
                                        setModal={setModal}
                                        folderName={children}
                                        setNotification={setNotification}
                                        notifyUser={notifyUser}
                                        folderId={folderId}
                                        setHoverable={setHoverable}
                                    />
                                )}
                                <div className='folder-name'>
                                    <div
                                        className='folder-name-icon-wrapper'
                                        onClick={(e) =>
                                            handleOpen(e, index, folderId)
                                        }
                                    >
                                        <div
                                            className={`${
                                                folder?.isPublic
                                                    ? 'public-folder-side-menu'
                                                    : undefined
                                            } folder-icon-wrapper`}
                                        >
                                            <h2 className='folder-name-аbbreviation'>
                                                {setFolderAbbriviation(
                                                    children
                                                )}
                                            </h2>
                                        </div>
                                        <p className='sibebar-inner-elements'>
                                            {textCutterForFoldersAndNewslettrsInFolders(
                                                children,
                                                15
                                            )}
                                        </p>
                                    </div>
                                    <div className='options-folder-wrapper'>
                                        <div
                                            onClick={(e) => handleOptions(e)}
                                            onBlur={(e) =>
                                                handleOptionsClose(e)
                                            }
                                            tabIndex={index}
                                            className='folder-options-side-menu'
                                        >
                                            <FolderOptionsIcon />
                                        </div>
                                        <ArrowFolder
                                            className={`${
                                                isOpened &&
                                                'arrow-user-folder-opened'
                                            } arrow-user-folder`}
                                            onClick={(e) =>
                                                handleOpen(e, index, folderId)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            {optionsCurrent && (
                                <FolderOptions
                                    positionY={positionYOptions}
                                    folderId={folderId}
                                    name={children}
                                    setFolderInfo={setFolderInfo}
                                    userId={userId}
                                    setOptionsCurrent={setOptionsCurrent}
                                    setModal={setModal}
                                    setNotification={setNotification}
                                    notifyUser={notifyUser}
                                    isPublic={folder.isPublic}
                                    setHoverable={setHoverable}
                                />
                            )}
                        </div>
                        {isOpened && (
                            <NewslettersInFolderRegular
                                name={children}
                                folder={folder}
                                setNotification={setNotification}
                            />
                        )}
                    </div>
                );
            }}
        </Droppable>
    );
};

const mapStateToProps = (state) => ({
    userId: getUserId(state),
    folders: getUserFolders(state),
    foldersOpen: getFoldersOpen(state)
});

const mapDispatchToProps = {
    addNewsletterToUsersFolder,
    openSpecificFolder,
    closeSpecificFolder,
};
export default connect(mapStateToProps, mapDispatchToProps)(Folder);
