import firebase from '../utils/firebase';

export const getToken = async () => {
    let { token } = await firebase.auth().currentUser.getIdTokenResult();

    return token;
};

export const countUserUnreadArticles = (articles, read) => {
    try {
        const result = articles.reduce(
            (acc, curr) => (read.find((x) => x === curr) ? acc : (acc += 1)),
            0
        );
        return result;
    } catch (e) {
        return 0;
    }
    
};
