import { openInputCreateFolder } from '../../../../../actions/sideMenuActions';
import {
    deleteFolder,
    makeFolderPublic,
    makeFolderPrivate,
} from '../../../../../actions/folderActions';
import { connect } from 'react-redux';
import { useEffect, useRef } from 'react';
import getFolderLink from '../../../../../helpers/getFolderLink';
import './FolderOptions.scss';

const FolderOptions = ({
    userId,
    folderId,
    name,
    openInputCreateFolder,
    setFolderInfo,
    deleteFolder,
    setOptionsCurrent,
    setModal,
    setNotification,
    notifyUser,
    makeFolderPublic,
    isPublic,
    makeFolderPrivate,
    setHoverable,
    positionY,
}) => {
    const ref = useRef();
    const folderLink = getFolderLink(folderId);

    const handleEditForm = () => {
        openInputCreateFolder();
        setFolderInfo({ name, folderId });
    };
    const handleDeleteFolder = async () => {
        try {
            await deleteFolder(userId, folderId);
            notifyUser(
                'success',
                'Successfully deleted folder!',
                setNotification
            );
        } catch (error) {
            notifyUser('error', error.error, setNotification);
        }
        setOptionsCurrent(false);
    };

    const handleMakePublic = async () => {
        setModal(true);
        setHoverable(false);
        setOptionsCurrent(false);
        try {
            await makeFolderPublic(userId, folderId);
        } catch (error) {
            notifyUser('error', error.error, setNotification);
        }
    };
    const handleMakePrivate = async () => {
        setOptionsCurrent(false);
        try {
            await makeFolderPrivate(userId, folderId);
            notifyUser('success', 'Folder is now private', setNotification);
        } catch (error) {
            notifyUser('error', error.error, setNotification);
        }
    };

    const handleCopyPublicLink = () => {
        navigator.clipboard.writeText(folderLink);
        notifyUser('success', 'Link Copied', setNotification);
        setOptionsCurrent(false);
    };
    // useEffect(() => {
    //     ref.current.scrollIntoView({
    //         behavior: 'smooth',
    //         block: 'nearest',
    //         inline: 'nearest',
    //     });
    // }, [makeFolderPublic]);

    return (
        <div
            className='options-folder-shown-absolute'
            style={{ top: `${positionY || 0}px` }}
        >
            <div className='options-folder-shown'>
                <ul className='options-from-menu-options' ref={ref}>
                    <li
                        className='option-menu-options'
                        onClick={handleEditForm}
                    >
                        Rename
                    </li>

                    {!isPublic ? (
                        <li
                            className='option-menu-options'
                            onClick={handleMakePublic}
                        >
                            Make Public
                        </li>
                    ) : (
                        <>
                            <li
                                className='option-menu-options'
                                onClick={handleCopyPublicLink}
                            >
                                Copy Public Link
                            </li>
                            <li
                                className='option-menu-options'
                                onClick={handleMakePrivate}
                            >
                                Make Private
                            </li>
                        </>
                    )}

                    <li
                        className='option-menu-options'
                        onClick={handleDeleteFolder}
                    >
                        Delete
                    </li>
                </ul>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    openInputCreateFolder,
    deleteFolder,
    makeFolderPublic,
    makeFolderPrivate,
};
export default connect(null, mapDispatchToProps)(FolderOptions);
