import { connect } from "react-redux";

import { openFeedbackModal, closePremiumFeaturesModal } from '../../../actions/sideMenuActions';

import './PremiumFeaturesModal.scss';

const PremiumFeaturesModal = ({ isOpen, openFeedbackModal, closePremiumFeaturesModal }) => {
  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();

    closePremiumFeaturesModal();
  }

  const handleFeedbackClicked = () => {
    closePremiumFeaturesModal();
    openFeedbackModal();
  }

  return (
    <div>
      <div className={`${isOpen ? 'open' : ''} premium-features-modal__container`}>
        <div className='premium-features-modal__content'>
          <span
            className='premium-features-modal__close-button'
            onClick={handleClose}
          >
            Close
          </span>

          <div className='premium-features-modal__form'>
            <p className='premium-features-modal__form-title'>
              Premium features are coming soon!
            </p>

            <p class="premium-features-modal__alternative-feedback">
              Anything you want to see? Let us know!
            </p>

            <div className='premium-features-modal__action-container'>
              <button
                className='premium-features-modal__cancel-button'
                onClick={handleClose}
              >
                Cancel
              </button>

              <button
                className="premium-features-modal__feedback-button"
                onClick={e => handleFeedbackClicked()}
              >
                Give feedback
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  closePremiumFeaturesModal,
  openFeedbackModal
};

export default connect(mapStateToProps, mapDispatchToProps)(PremiumFeaturesModal);